import React, { useState } from "react";
import Lightbox from "react-spring-lightbox";
import styles from "./PhotographyPatternPage.module.scss";
import TitleContentLockup from "components/global/TitleContentLockup";
import ArrowButton from "../../global/ArrowButton";
import ImageDownload from "./ImageDownload";

const PhotographyPatternPage = ({ content }) => {
  const [currentImageIndex, setCurrentIndex] = useState(0);
  const [toggleLightbox, setToggleLightBox] = useState(false);
  const images = content.lightBoxImages;
  const downloads = content.downloadImages;

  const gotoPrevious = () =>
    currentImageIndex > 0 && setCurrentIndex(currentImageIndex - 1);

  const gotoNext = () =>
    currentImageIndex + 1 < images.length &&
    setCurrentIndex(currentImageIndex + 1);

  const toggle = () => {
    if (currentImageIndex !== 0) {
      setCurrentIndex(0);
    }
    setToggleLightBox(!toggleLightbox);
  };

  const CloseButton = () => {
    return (
      <button className={styles.closeButton} onClick={toggle}>
        <img
          src="images/svg/close-icon-dark.svg"
          alt="Close Icon"
          className={styles.closeIcon}
        />
      </button>
    );
  };

  return (
    <div className={styles.container}>
      <TitleContentLockup
        title1={content.titleLockup.title1}
        title={content.titleLockup.title}
        path={content.titleLockup.path}
        text={content.titleLockup.text}
      />
      <button className={styles.photoIcon} onClick={toggle}>
        {toggleLightbox ? (
          ""
        ) : (
          <img
            src={content.photoIcon}
            alt="Lightbox Icon"
            className={styles.photoIcon}
          />
        )}
      </button>
      <div className={styles.infoContainer}>
        {toggleLightbox ? (
          ""
        ) : (
          <button className={styles.button} onClick={toggle}>
            <img
              src={content.arrowIcon}
              className={styles.arrowIcon}
              alt="Arrow Icon"
            />
          </button>
        )}
      </div>
      <Lightbox
        isOpen={toggleLightbox}
        onPrev={gotoPrevious}
        onNext={gotoNext}
        images={images}
        currentIndex={currentImageIndex}
        /* Add your own UI */
        renderHeader={() => <CloseButton />}
        // renderFooter={() => (<CustomFooter />)}
        // renderPrevButton={() => (<CustomLeftArrowButton />)}
        renderNextButton={() => <ArrowButton next={gotoNext} />}
        renderImageOverlay={() => (
          <ImageDownload
            downloadLink={downloads[currentImageIndex].src}
            fileName={downloads[currentImageIndex].fileName}
          />
        )}
        /* Add styling */
        className={styles.lightBox}
        style={{ background: "rgba(60, 61, 68, 0.75)" }}
        /* Handle closing */
        onClose={toggle}

        /* Use single or double click to zoom */
        // singleClickToZoom

        /* react-spring config for open/close animation */
        // pageTransitionConfig={{
        //   from: { transform: 'scale(0.75)', opacity: 0 },
        //   enter: { transform: 'scale(1)', opacity: 1 },
        //   leave: { transform: 'scale(0.75)', opacity: 0 },
        //   config: { mass: 1, tension: 320, friction: 32 }
        // }}
      />
    </div>
  );
};

export default PhotographyPatternPage;
