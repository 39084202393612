import React from "react";
import styles from "./ArrowButton.module.scss";

const ArrowButton = props => {
  return (
    <div>
      <button className={styles.button} onClick={props.next}>
        <img
          src="/images/svg/right-arrow-icon-light.svg"
          className={styles.arrowIcon}
          alt="Arrow Icon"
        />
      </button>
    </div>
  );
};

export default ArrowButton;
