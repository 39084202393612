import React from "react";
import styles from "./IconographyPage.module.scss";
import TitleContentLockup from "components/global/TitleContentLockup";
import PageNumber from "components/global/PageNumber";

const IconographyPage = ({ content, sectionTitle }) => {
  return (
    <div className={styles.container}>
      <div className={styles.innerContainer}>
        <TitleContentLockup
          title={content.titleLockup.title}
          path={content.titleLockup.path}
          text={content.titleLockup.text}
        />
        <div className={styles.infoContainer}>
          <img
            src={content.image}
            className={styles.icons}
            alt="Synchrony HOME icons"
          />
        </div>
      </div>
      <PageNumber
          sectionTitle={sectionTitle}
          pageNumber={content.pageNumber}
        />
    </div>
  );
};

export default IconographyPage;
