import React, { useState, useEffect } from "react";
import Tooltip from "./Tooltip";
import MobileMenu from "./MobileMenu";
import styles from "./Header.module.scss";
import * as Scroll from "react-scroll";
import {
  brandScroll,
  elementsScroll,
  assetsScroll,
  contactScroll
} from "utils/helpers";
import { Link } from "react-scroll";
import { getQuickTips } from "../../data/tooltip";
import { useSpring, animated } from "react-spring";
import {
  useStateWithLocalStorage,
  stringToHash,
  useWindowSize
} from "../../utils/helpers";

const Header = ({
  toggle,
  mobileToggle,
  openTooltip,
  content,
  openMobileMenu,
  setMobileToggle,
  closeMobileMenu
}) => {
  const [value, setValue] = useStateWithLocalStorage("QuickTipsCount");
  const [notifViewed, setViewed] = useState(false);
  const [notifCount, setCount] = useState(0);
  const scroll = Scroll.animateScroll;
  const size = useWindowSize();
  const tooltipShow = toggle ? (
    <Tooltip toggle={toggle} content={content} />
  ) : (
    ""
  );
  const mobileMenuShow = mobileToggle ? (
    <MobileMenu
      toggle={mobileToggle}
      setMobileToggle={setMobileToggle}
      closeMobileMenu={closeMobileMenu}
    />
  ) : (
    ""
  );

  const [animate, set] = useSpring(() => ({
    to: { transform: "rotate(0deg)" }
  }));
  set({ transform: toggle ? "rotate(180deg)" : "rotate(0deg)" });

  const updateString = getQuickTips().map(update => {
    let currentUpdates = stringToHash(update.hashText);
    return currentUpdates;
  });

  const [animateBurger, setburger] = useSpring(() => ({
    to: { transform: "rotate(0deg)" }
  }));
  setburger({ transform: mobileToggle ? "rotate(180deg)" : "rotate(0deg)" });

  useEffect(() => {
    let newTotal;
    let currentValue = updateString;
    let findDuplicates = currentValue.map((dup, idx) => {
      let count;
      if (value.indexOf(dup) !== idx) {
        count = dup;
      }
      return count;
    });
    findDuplicates = findDuplicates.filter(item => item !== undefined);

    if (value !== currentValue) {
      newTotal = findDuplicates.length;
    }
    setCount(newTotal);
  }, [updateString, value]);

  const onChange = () => {
    setViewed(true);
    setValue(updateString);
  };

  let prevScrollpos = window.pageYOffset;
  window.onscroll = function() {
    let prevValueScrollPos = window.pageYOffset;

    if (prevScrollpos > prevValueScrollPos) {
      document.getElementById("navbar").style.top = "0";
    } else if (!toggle && size.width > 1200) {
      document.getElementById("navbar").style.top = "-75px";
    }
    prevScrollpos = prevValueScrollPos;
  };

  return (
    <>
      <div className={styles.container} id="navbar">
        <div className={styles.logoContainer} onClick={scroll.scrollToTop}>
          <img
            src="/images/home_brand-mark.png"
            className={styles.logo}
            alt="Synchrony HOME&trade; Brand Mark"
          />
          <span>Brand Style Guide</span>
        </div>
        <div className={styles.innerWrapperMobile}>
          <div className={styles.tipsContainer} onClick={onChange}>
            <button className={styles.quickTipsButton} onClick={openTooltip}>
              <animated.div style={animate} className={styles.arrowContainer}>
                <img
                  src="/images/svg/chevron-down-icon.svg"
                  className={styles.quickTipsButtonIcon}
                  alt="chevron up icon"
                />
              </animated.div>
              <span>Quick Tips</span>
            </button>
            {notifViewed || notifCount <= 0 ? (
              ""
            ) : (
              <div className={styles.alertContainer}>
                <div className={styles.alert}>{notifCount}</div>
              </div>
            )}
          </div>
          <animated.div style={animateBurger}>
            <button
              className={styles.mobileMenuButton}
              onClick={openMobileMenu}
            >
              <img
                src={
                  mobileToggle
                    ? "/images/svg/close-icon-white.svg"
                    : "/images/svg/hamburger-menu-icon.svg"
                }
                className={styles.hamburgerIcon}
                alt={mobileToggle ? "Close Menu" : "Open Menu"}
              />
            </button>
          </animated.div>
        </div>
        <div className={styles.innerWrapper}>
          <div className={styles.links}>
            <Link
              to="brand-section"
              onClick={brandScroll}
              spy={true}
              className={styles.link}
            >
              The Brand
            </Link>
            <Link
              to="elements-section"
              onClick={elementsScroll}
              spy={true}
              className={styles.link}
            >
              Brand Elements
            </Link>
            <Link
              to="assets-section"
              onClick={assetsScroll}
              spy={true}
              className={styles.link}
            >
              Digital Assets
            </Link>
            <Link
              to="contact-section"
              onClick={contactScroll}
              spy={true}
              className={styles.link}
            >
              Contact Us
            </Link>
            <div className={styles.tipsContainer} onClick={onChange}>
              <button className={styles.quickTipsButton} onClick={openTooltip}>
                <animated.div style={animate} className={styles.arrowContainer}>
                  <img
                    src="/images/svg/chevron-down-icon.svg"
                    className={styles.quickTipsButtonIcon}
                    alt="chevron up icon"
                  />
                </animated.div>
                Quick Tips
              </button>
              {notifViewed || notifCount <= 0 ? (
                ""
              ) : (
                <div className={styles.alertContainer}>
                  <div className={styles.alert}>{notifCount}</div>
                </div>
              )}
            </div>
          </div>
          <span className={styles.button}>
            <a
              href="https://look-listen-public.s3-us-west-2.amazonaws.com/homebrandguide/SYC-Brand-Guidelines-052021.pdf"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.buttonInner}
            >
              Download with Inka&trade;
            </a>
          </span>
        </div>
      </div>
      {tooltipShow}
      {mobileMenuShow}
    </>
  );
};

export default Header;
