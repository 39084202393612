import React from "react";
import styles from "../../SocialMediaSection.module.scss";
import TitleContentLockup from "components/global/TitleContentLockup";

const OrganicInstagram = ({ content }) => {
  return (
    <div className={styles.containerDark}>
      <div className={styles.innerContainer}>
        <TitleContentLockup
          title={content.titleLockup.title}
          subTitle={content.titleLockup.subTitle}
          text={content.titleLockup.text}
        />
        <div className={styles.infoContainer}>
          <h3 className={styles.header}>
            <img src={content.instagramIcon} alt="Instagram" /> Instagram
          </h3>
          <p className={styles.text}>
            This is a highly visual platform that can be used to share what the
            Synchrony HOME program can help cardholders achieve in their homes.
          </p>
          <ul className={styles.list}>
            <li>
              Keep in mind this audience is viewing images on their mobile
              phones.
            </li>
            <li>
              Copy is used solely to add context to the image. Ideal character
              counts for posts range from 120-140 characters. However, only the
              first ~100 characters will be visible in the feed.
            </li>
            <li>
              <a href="https://www.facebook.com/ads/tools/text_overlay" target="_blank" rel="noopener noreferrer" className={styles.link}>
                No more than 20% of the image should contain text.
              </a>
            </li>
            <li>The recommended number of hashtags is between three and 10.</li>
            <li>
              Image:{" "}
              <span className={styles.thin}>
                Size:1080 x 1080 pixels; Ratio: 1:1
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default OrganicInstagram;
