import React from 'react';
import styles from "./PhotographyPatternPage.module.scss";

const ImageDownload = (props) => (
  <a
    href={props.downloadLink}
    target="_blank"
    rel="noopener noreferrer"
    download={props.fileName}
    className={styles.downloadLink}>
    <img
      className={styles.imageDownloadIcon}
      id="imgDownloadIcon"
      src="images/svg/download-icon-white.svg"
      alt=""
    />
  </a>
);
 
export default ImageDownload;