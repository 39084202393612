import React from "react";
import styles from "./GuideIntro.module.scss";
import TitleContentLockup from "components/global/TitleContentLockup";
import PageNumber from "components/global/PageNumber";
import {
  brandScroll,
  elementsScroll,
  assetsScroll,
  contactScroll
} from "utils/helpers";

const GuideIntro = ({ content }) => {
  return (
    <div>
    <div className={styles.container}>
      <TitleContentLockup title1={content.title1} title={content.title} path={content.path} />
      <div className={styles.infoContainer}>
        <div className={styles.textLockup}>
          <p>{content.text1}</p>
          <p>{content.text2}</p>
          <p>
            For questions and approvals, contact:{" "}
            <span className={styles.bold}>Kirsty&nbsp;Wertz</span> at
            <a className={styles.link} href="mailto:kirsty.wertz@syf.com">
              kirsty.wertz@syf.com
            </a>
            .
          </p>
          <div className={styles.listLockup}>
            <p>
              The Synchrony HOME Brand Style Guide is organized into four
              distinct sections:
            </p>
            <ul className={styles.list}>
              <li>
                <span className={styles.bold}>1</span>{" "}
                <button className={styles.link} onClick={brandScroll}>
                  THE BRAND
                </button>
              </li>
              <li>
                <span className={styles.bold}>2</span>{" "}
                <button className={styles.link} onClick={elementsScroll}>
                  BRAND ELEMENTS
                </button>
              </li>
              <li>
                <span className={styles.bold}>3</span>{" "}
                <button className={styles.link} onClick={assetsScroll}>
                  DIGITAL ASSETS
                </button>
              </li>
              <li>
                <span className={styles.bold}>4</span>{" "}
                <button className={styles.link} onClick={contactScroll}>
                  CONTACT
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
      </div>
      <PageNumber pageNumber={content.pageNumber} />
    </div>
  );
};

export default GuideIntro;
