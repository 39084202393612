import React from "react";
import { Element } from "react-scroll";
import SectionHeaderPage from "components/global/SectionHeaderPage";
import VoiceTonePage from "./VoiceTonePage";
import NameUsagePage from "./NameUsagePage";
import BrandPositioningSection from "./BrandPositioningSection";

const TheBrandSection = ({ content }) => {
  return (
    <Element id="brand-section" name="brand-section">
      <div>
        <SectionHeaderPage
          title={content.headerPages.theBrand.title}
          backgroundImg={content.headerPages.theBrand.backgroundImg}
        />
        <BrandPositioningSection
          sectionTitle={content.theBrand.sectionTitle}
          content={content.theBrand}
        />
        <VoiceTonePage content={content.theBrand.voiceTone} />
        <NameUsagePage
          sectionTitle={content.theBrand.sectionTitle}
          content={content.theBrand.nameUsage}
        />
      </div>
    </Element>
  );
};

export default TheBrandSection;
