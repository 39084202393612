import React from "react";
import styles from "./BrandDontsPage.module.scss";
import PageNumber from "components/global/PageNumber";
import SubSectionHeader from "components/global/SubSectionHeader";

const BrandDontsPage = ({ content, sectionTitle }) => {
  return (
    <div className={styles.container}>
      <SubSectionHeader />
      <div className={styles.wrapper}>
        <div className={styles.innerContainer}>
          <h3 className={styles.header}>{content.header}</h3>
          <div className={styles.imageContainer}>
            <div className={styles.imageInner}>
              <img
                src="/images/home_card-art-dont-outline.png"
                className={styles.image}
                alt="Synchrony HOME card with strike"
              />
              <span className={styles.subText}>
                DO NOT outline the brand mark.
              </span>
            </div>
            <div className={styles.imageInner}>
              <img
                src="/images/home_card-art-removed-elements.png"
                className={styles.image}
                alt="Synchrony HOME card with strike"
              />
              <span className={styles.subText}>
                DO NOT remove elements of the brand mark.
              </span>
            </div>
            <div className={styles.imageInner}>
              <img
                src="/images/home_card-art-dont-modify.png"
                className={styles.image}
                alt="Synchrony HOME card with strike"
              />
              <span className={styles.subText}>
                DO NOT modify or recreate the provided artwork.
              </span>
            </div>
            <div className={styles.imageInner}>
              <img
                src="/images/home_card-art-logo-sentence.png"
                className={styles.image}
                alt="Synchrony HOME card with strike"
              />
              <span className={styles.subText}>
                DO NOT use the logo in a sentence.
              </span>
            </div>
            <div className={styles.imageInner}>
              <img
                src="/images/home_card-art-no-layer.png"
                className={styles.image}
                alt="Synchrony HOME card with strike"
              />
              <span className={styles.subText}>
                DO NOT layer content on top of the brand mark.
              </span>
            </div>
          </div>
        </div>
      </div>
      <PageNumber
          sectionTitle={sectionTitle}
          pageNumber={content.pageNumber}
        />
    </div>
  );
};

export default BrandDontsPage;
