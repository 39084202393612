import React from "react";
import SocialMediaIntro from "./SocialMediaIntro";
import SocialMediaOrganic from "./SocialMediaOrganic";
import SocialMediaPaid from "./SocialMediaPaid";

const SocialMediaSection = ({ content, sectionTitle }) => {
  return (
    <div>
      <SocialMediaIntro
        sectionTitle={sectionTitle}
        content={content.socialMediaIntro}
      />
			<SocialMediaOrganic 
				sectionTitle={sectionTitle} 
				content={content} 
			/>
			<SocialMediaPaid 
				sectionTitle={sectionTitle} 
				content={content} 
			/>
    </div>
  );
};

export default SocialMediaSection;
