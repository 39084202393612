import React from "react";
import styles from "../BannerAdsSection.module.scss";
import TitleContentLockup from "components/global/TitleContentLockup";

const BannerAdsIntro = ({ content }) => {
  return (
    <div className={styles.container}>
      <div className={styles.innerContainer}>
        <TitleContentLockup title={content.titleLockup.title} />
        <div className={styles.infoContainer}>
          <p className={styles.text}>{content.bannerAdsIntro.text1}</p>
          <p className={styles.text}>{content.bannerAdsIntro.text2}</p>
        </div>
      </div>
    </div>
  );
};

export default BannerAdsIntro;
