import React from "react";
import { useInView } from "react-intersection-observer";
import { useSpring, animated } from "react-spring";
import styles from "./InspirationPage.module.scss";
import TitleContentLockup from "components/global/TitleContentLockup";
import PageNumber from "components/global/PageNumber";
import DownloadImage from "../../global/DownloadImage/DownloadImage";


const InspirationPage = ({ content, sectionTitle }) => {
  const [ref, inView] = useInView({
    threshold: 0.1
  });
  const [animate, set] = useSpring(() => ({ left: 3000, position:'relative' }));
    set({ left: inView ? 0 : 3000, delay: 500 });

  return (
    <div>
      <div className={styles.container} ref={ref}>
        <TitleContentLockup
          title={content.titleLockup.title}
          text={content.titleLockup.text}
          
        />
        <div
          className={styles.infoContainer}
          id={inView ? styles.dashEffect : ""}
        >
          <animated.div style={animate}>
            <DownloadImage
              downloadLink="/images/download/SYF-HOME-Card-Art-Single-Card-(Primary).zip"
              fileName="SYF-HOME-Card-Art-Single-Card-(Primary).zip"
              imageLink={content.cardArt}
              class="grid-image"
            />
          </animated.div>
        </div>
      </div>
      <PageNumber sectionTitle={sectionTitle} pageNumber={content.pageNumber} />
    </div>
  );
};

export default InspirationPage;
