const guideIntroPage = {
  title1: `Purpose of`,
  title: `this guide`,
  pageNumber: `2`,
  path: `Guidelines`,
  text1: `The Synchrony HOME program offers cardholders a convenient way to pay for purchases at thousands of participating retailers nationwide, helping them create the home of their dreams with a single credit card they can use again and again.`,
  text2: `The purpose of this style guide is to outline the components of the Synchrony HOME brand to our internal and external audiences, including positioning, voice and tone, design elements, photographic style, color palette, typography and more. This style guide should be referenced for all client- and partner-facing materials created on behalf of the Synchrony HOME Credit Card, ensuring a unique and consistent brand story.`
};
const copyGuidanceTemp = {
  pageNumber: `3`,
  titleLockup: {
    title1: `Temporary`,
    title: `Copy Guidance`,
    text: `Until further notice, a temporary hold is being placed on any references to world-spend (out of network) card utility and val prop mentions are being used selectively. Avoid references to “over 1 million locations” or “one card for everything in your home” and instead use “Accepted at thousands of retailers, from furniture and décor to appliances and more.” For more detail on this, please contact Kirsty Wertz or Jenna Clay.`
  }
};

const headerPages = {
  theBrand: {
    title: `The Brand`,
    backgroundImg: `/images/style_guide_02-the-brand.jpg`
  },
  brandElements: {
    title: `Brand Elements`,
    backgroundImg: `/images/style_guide_03-brand-elements.jpg`
  },
  digitalAssets: {
    title: `Digital Assets`,
    backgroundImg: `/images/style_guide_05-digital-assets.jpg`
  }
};

const theBrand = {
  sectionTitle: "The Brand",
  valuePage: {
    pageNumber: `5`,
    header: `Value Proposition`,
    path: `Art and Collateral`
  },
  brandPage: {
    pageNumber: `5`,
    titleLockup: {
      title1:`Brand`,
      title: `Positioning`,
      text: `Here you'll find the unique benefits Synchrony HOME offers cardholders. Share these with your team, and incorporate them into your sales approach, as well as in advertising and marketing materials, to help attract customers and increase sales.`
    },
    bodyHeader: "Positioning Statement:",
    bodyText:
      "The Synchrony HOME Credit Card helps make it easy to create the home of your dreams. Shop at more than a thousand retailers nationwide for furniture, decor, appliances, and more."
  },
  voiceTone: {
    titleLockup: {
      title: `Voice & Tone`
    },
    bodyText: `Just like a dream home itself, the Synchrony HOME program is aspirational. With that in mind, our brand voice should be positive and engaging. Brand communications, while clear and succinct, should take a pleasant and casual tone — like that of a helpful friend. It’s important to emphasize and reiterate to prospects and cardholders that their dream home is possible, and the Synchrony HOME program can help them realize it.`
  },
  valPropTips: {
    pageNumber: `5`,
    header: `Val Prop Tips`,
    subHeaders: [
      `Regarding 6 months promotional financing`,
      `Regarding 2% cash back`
    ]
  },
  benefitsPage: {
    pageNumber: `5`,
    header: `Additional Benefits`,
    listItems: [
      `$0 fraud liability`,
      `No annual fee${String.fromCharCode(179)}`,
      `Ability to request a credit limit increase${String.fromCharCode(8308)}`,
      `Free access to Your Credit Score`,
      `MySynchrony Mobile app${String.fromCharCode(8309)} (iOS & Android)`
    ],
    disclosures: [
      `${String.fromCharCode(
        179
      )} For new accounts: Purchase APR is 29.99%; Minimun Interest Charge is $2. Existing cardholders should see their cardholder agreements for their applicable terms.`,
      `${String.fromCharCode(8308)} Cardholders may be required to provide their annual net income if their recent information is not on file. Cardholder income will be used as a
      factor to determine eligibility for a credit line increase.`,
      `${String.fromCharCode(8309)} Data fees may apply.`
    ]
  },
  nameUsage: {
    pageNumber: `7`,
    titleLockup: {
      title: `Name Usage`
    },
    header: `Additionally`,
    listItems: [
      `Synchrony HOME Credit Card is a proper noun and should always be capitalized as one.`,
      `Synchrony HOME is the appropriate way to refer to the brand and the network overall in B2C communications.`,
      `For B2B, the brand can be referred to as the Synchrony HOME Credit Card or Synchrony HOME program.`,
      `Your credit card is acceptable for use within small word counts 1) if the card art is present, and 2) the audience is current cardholders.`,
      `HOME may never be used on its own, or used in all caps to refer to the general concept of home.`,
      `Cardholders (one word) should be used when paired with the name of the credit card: Synchrony HOME Credit Cardholders. In all other applications, cardholders is lowercase.`
    ]
  }
};

const brandElements = {
  sectionTitle: "Brand Elements",
  inspirationPage: {
    pageNumber: `9`,
    titleLockup: {
      title: `Inspiration`,
      text: `The Synchrony HOME Credit Card sets itself apart with a cheerful turquoise hue that represents the energy and positivity of the brand, and welcomes cardholders home with every purchase. The card’s gold core serves as a reminder of Synchrony’s buying power, which can be leveraged to help realize cardholders’ ambitions for their home — today and in the future.`
    },
    cardArt: "/images/home_blue-card-art-shadowboxed-large.png",
    downloadIcon: "/images/svg/download-icon.svg"
  },
  colorPalette: {
    pageNumber: `10`,
    titleLockup: {
      title: `Color Palette`,
      text: `The five core colors of Synchrony HOME — taken from the full Synchrony palette — define and elevate the brand.`
    },
    copyIcon: "/images/svg/copy-icon-white.svg",
    copyIconDark: "/images/svg/copy-icon-dark.svg"
  },
  fontPage: {
    pageNumber: `11`,
    titleLockup: {
      title: `Font`,
      text: `Reinforce the corporate brand by using Synchrony Sans, a modern and open font. Illustrated here is the Synchrony Sans typeface in thin, regular, medium and bold weights.`
    },
    header: `Synchrony Sans`,
    downloadIcon: "/images/svg/download-icon.svg"
  },
  iconographyPage: {
    pageNumber: `12`,
    titleLockup: {
      title: `Iconography`,
      path: `Art and Collateral >>`,
      text: `Synchrony icons should be used sparingly and consistently to identify the various markets, as well as to direct the viewer to specific calls to action, such as downloading the app, visiting the home page, etc. A sample icon set can be referenced here.`
    },
    image: "/images/home_iconography-icons.png"
  },
  photographyPatternPage: {
    titleLockup: {
      title1:`Photography`,
      title: `& Pattern`,
      path: `Photos`,
      text: `Mimicking Synchrony’s corporate brand, photos should feel engaging, authentic and modern. Photos should reflect the moments and memories people are creating, rather than the things they’re buying. Patterns of natural materials found in the home will complement the photography and can be used as a texture to create interest and dimension.`
    },
    image: "/images/style_guide_04-woman-man-laughing.jpg",
    lightBoxImages: [
      {
        src: `/images/home_lightbox-slide14.jpg`,
        alt: "Man Woman on Couch"
      },
      {
        src: `/images/home_lightbox-slide1.jpg`,
        alt: "Man Woman Laughing"
      },
      {
        src: `/images/home_lightbox-slide3.jpg`,
        alt: "Baking"
      },
      {
        src: `/images/home_lightbox-slide4.jpg`,
        alt: "Family Backyard"
      },
      {
        src: `/images/home_lightbox-slide15.jpg`,
        alt: "Family in Kitchen"
      },
      {
        src: `/images/home_lightbox-slide16.jpg`,
        alt: "Family in empty home"
      },
      {
        src: `/images/home_lightbox-slide5.jpg`,
        alt: "Family on couch"
      },
      {
        src: `/images/home_lightbox-slide6.jpg`,
        alt: "Family tent"
      },
      {
        src: `/images/home_lightbox-slide2.jpg`,
        alt: "Party"
      },
      {
        src: `/images/home_lightbox-slide7.jpg`,
        alt: "Blue tile"
      },
      {
        src: `/images/home_lightbox-slide8.jpg`,
        alt: "Brick"
      },
      {
        src: `/images/home_lightbox-slide9.jpg`,
        alt: "Brick"
      },
      {
        src: `/images/home_lightbox-slide10.jpg`,
        alt: "Brick"
      },
      {
        src: `/images/home_lightbox-slide11.jpg`,
        alt: "Brick"
      },
      {
        src: `/images/home_lightbox-slide12.jpg`,
        alt: "Brick"
      },
      {
        src: `/images/home_lightbox-slide13.jpg`,
        alt: "Brick"
      },
    ],
    downloadImages: [
      {
        src: `/images/download/SYF_HOME_Image_Couple_on_Couch.jpg`,
        fileName: "SYF_HOME_Image_Couple_on_Couch.jpg"
      },
      {
        src: `/images/download/SYF_HOME_Image_Couple_Painting.jpg`,
        fileName: "SYF_HOME_Image_Couple_Painting.jpg"
      },
      {
        src: `/images/download/SYF_HOME_Image_Family_Backyard.jpg`,
        fileName: "SYF_HOME_Image_Family_Backyard.jpg"
      },
      {
        src: `/images/download/SYF_HOME_Image_Family_Baking.jpg`,
        fileName: "SYF_HOME_Image_Family_Baking.jpg"
      },
      {
        src: `/images/download/SYF_HOME_Image_Family_Blue_Kitchen.jpg`,
        fileName: "SYF_HOME_Image_Family_Blue_Kitchen.jpg"
      },
      {
        src: `/images/download/SYF_HOME_Image_Family_Hardwood_Floors.jpg`,
        fileName: "SYF_HOME_Image_Family_Hardwood_Floors.jpg"
      },
      {
        src: `/images/download/SYF_HOME_Image_Family_on_Couch.jpg`,
        fileName: "SYF_HOME_Image_Family_on_Couch.jpg"
      },
      {
        src: `/images/download/SYF_HOME_Pattern_Blue_Tile.jpg`,
        fileName: "SYF_HOME_Pattern_Blue_Tile.jpg"
      },
      {
        src: `/images/download/SYF_HOME_Image_Party.jpg`,
        fileName: "SYF_HOME_Image_Party.jpg"
      },
      {
        src: `/images/download/SYF_HOME_Pattern_Brick.jpg`,
        fileName: "SYF_HOME_Pattern_Brick.jpg"
      },
      {
        src: `/images/download/SYF_HOME_Pattern_Gray_Wood.jpg`,
        fileName: "SYF_HOME_Pattern_Gray_Wood.jpg"
      },
      {
        src: `/images/download/SYF_HOME_Pattern_Hexagon_Tile.jpg`,
        fileName: "SYF_HOME_Pattern_Hexagon_Tile.jpg"
      },
      {
        src: `/images/download/SYF_HOME_Pattern_Marble.jpg`,
        fileName: "SYF_HOME_Pattern_Marble.jpg"
      },
      {
        src: `/images/download/SYF_HOME_Pattern_Ombre.jpg`,
        fileName: "SYF_HOME_Pattern_Ombre.jpg"
      },
      {
        src: `/images/download/SYF_HOME_Pattern_Teal_Pearl.jpg`,
        fileName: "SYF_HOME_Pattern_Teal_Pearl.jpg"
      },
      {
        src: `/images/download/SYF_HOME_Pattern_Wood_Look.jpg`,
        fileName: "SYF_HOME_Pattern_Wood_Look.jpg"
      },
    ],
    arrowIcon: "/images/svg/right-arrow-icon-dark.svg",
    photoIcon: "/images/svg/photo-lightshow-icon.svg"
  },
  brandMarkSection: {
    brandMarkIntroPage: {
      pageNumber: `14`,
      titleLockup: {
        title1: `Synchrony HOME`,
        title2:  `Credit Card`,
        title: `Brand Mark`,
        path: `Art and Collateral`,
        text: `The brand mark consists of three elements: the
        logotype that says Synchrony HOME™; the
        logomark that we call the Pillars, which is always
        stacked and centered above the logotype; and
        the carrier that always encloses the logomark
        and logotype.`,
        text2: `Our logotype can never appear without the
        Pillars, and the Pillars are not to appear without
        the logotype. The lockup shown here is the only
        approved format for the Synchrony HOME brand.`
      },
      image: "/images/home_card-art-diagram.png",
      downloadIcon: "/images/svg/download-icon.svg",
      disclaimer: `Note: Do not attempt to recreate the brand mark on your own. Always use the official, approved brand mark files.`
    },
    brandSpacingPage: {
      pageNumber: `15`,
      headers: ["Brand mark sizing", "Brand mark spacing requirements"],
      images: [
        "/images/home_card-art-size-diagram.png",
        "/images/home_card-art-size-diagram-2.png"
      ]
    },
    brandColorVariationsPage: {
      pageNumber: `16`,
      header: `Brand mark color variations`,
      downloadIcon: "/images/svg/download-icon.svg",
      images: [
        "/images/home_full-color-card-logo.png",
        "/images/home_full-color-border-logo.png",
        "/images/home_white-card-logo.png",
        "/images/home_one-color-card-logo.png"
      ],
      alt: [
        "Full Color Card",
        "Full Color With Border",
        "White Card",
        "One Color Card"
      ]
    },
    brandDontsPage: {
      pageNumber: `17`,
      header: `Brand mark dont's`,
      pageContent: {
        images: [
          "/images/home_card-art-dont-outline.png",
          "/images/home_card-art-removed-elements.png",
          "/images/home_card-art-dont-modify.png",
          "/images/home_card-art-logo-sentence.png",
          "/images/home_card-art-no-layer.png"
        ],
        imageText: [
          "DO NOT outline the brand mark.",
          "DO NOT remove elements of the brand mark.",
          "DO NOT modify or recreate the provided artwork.",
          "DO NOT use the logo in a sentence.",
          "DO NOT layer content on top of the brand mark."
        ]
      }
    }
  },
  cardArtPage: {
    pageNumber: `18`,
    titleLockup: {
      title: `Card Art`,
      path: `Art and Collateral`,
      text: `In digital and print communications that will be sent to all current cardholders but cannot be segmented via specific card, use any of the following:`,
      listItem1: `HOME brand mark`,
      listItem2: `HOME neutral card spray (do not show the teal card when talking to custom-client cardholders)`
    },
    pageContent: {
      images: [
        "/images/home_blue-card-art-shadowboxed-square.png",
        "/images/home_blue-card-art-flat.png",
        "/images/home_spray-card-art.png",
        "/images/home_blue-card-art-flat-bus.png",
        "/images/home_blue-dark-card-art-small.png"
      ],
      imageText: [
        "Single Card (Primary)",
        "Single Card (Secondary)",
        "Two-Card Neutral Spray",
        "Single Card (Business)",
        "Brand Mark"
      ]
    },
    downloadIcon: "/images/svg/download-icon.svg",
    disclaimer: [
      `Note: Do not attempt to recreate card art on your own. Always use the official, approved files.`
    ]
  },
  welcomeHomePage: {
    pageNumber: `19`,
    titleLockup: {
      title: `"Welcome Home”
            Design Element`,
      path: `Art and Collateral`,
      text: `The “welcome home™” design element engages the consumer and reinforces the primary purpose of the credit card. This element may be used in conjunction with the card art but should not be used next to the card art.`
    },
    image: "/images/home_welcome-home-design-text.png",
    downloadIcon: "/images/svg/download-icon.svg",
    disclaimer: `Note: Do not attempt to recreate the design element on your own. Always use the official, approved files.`
  },
  dreamHomePage: {
    pageNumber: `20`,
    titleLockup: {
      title1: `"Dream Home,`,
      title2: ` Realized”`,
      title: `Design Element`,
      path: `Art and Collateral`,
      text: `The “dream HOME realized” design element conveys that it’s easier than ever for cardholders to make their home everything they’ve dreamed of. If the statement appears in text it should read as, “Dream home, realized” in the Synchrony Sans typeface.`
    },
    image: "/images/home_dream-home-text.png",
    downloadIcon: "/images/svg/download-icon.svg",
    disclaimer: `Note: Do not attempt to recreate the design element on your own. Always use the official, approved files.`
  },
  goldCorePage: {
    pageNumber: `21`,
    header: `Examples`,
    titleLockup: {
      title: `"Gold Core”
            Design Element`,
      text1: `The gold core of the Synchrony HOME Credit Card reminds both retailers and consumers of the power of the
            Synchrony brand.`,
      text2: `To retailers, it communicates the partnership that empowers them to build their business with proven expertise
            and support.`,
      text3: `To consumers, it communicates the buying power that supports their aspirations of having a life of “more.” Overall, Synchrony is at the core of achieving goals, whether business or personal. Use the horizontal gold line sparingly to highlight or draw attention to elements within the design.`
    },
    cardArt: "/images/home_blue-card-art-shadowboxed-large.png"
  }
};

const digitalAssets = {
  sectionTitle: "Digital Assets",
  digitalAssetsIntro: {
    pageNumber: `23`,
    titleLockup: {
      title: `Digital Assets`,
      text: `These lockups and collateral pieces demonstrate the look and feel of the Synchrony HOME brand. By using the prescribed color palette, fonts, photography style and design elements, the brand will feel cohesive, consistent and credible.`
    }
  },
  socialMediaSection: {
    titleLockup: {
      title: `Social Media Platforms`
    },
    socialMediaIntro: {
      pageNumber: `24`,
      titleLockup: {
        title: `Social Media Platforms`
      }
    },
    socialMediaOrganic: {
      pageNumber: `25`,
      titleLockup: {
        title: `Social Media Platforms`,
        subTitle: `Organic`
      },
      facebookIcon: "/images/svg/facebook-square-icon.svg",
      instagramIcon: "/images/svg/instagram-square-icon.svg",
      pinterestIcon: "/images/svg/pinterest-square-icon.svg"
    },
    socialMediaPaid: {
      pageNumber: `26`,
      titleLockup: {
        title: `Social Media Platforms`,
        subTitle: `PAID`
      },
      facebookIcon: "/images/svg/facebook-square-icon.svg",
      instagramIcon: "/images/svg/instagram-square-icon.svg",
      pinterestIcon: "/images/svg/pinterest-square-icon.svg"
    }
  },
  bannerAdsSection: {
    pageNumber: `27`,
    titleLockup: {
      title: `Banner Ads`
    },
    bannerAdsIntro: {
      text1: `Consumers will experience the Synchrony HOME brand in various digital applications such as the website, social media platforms like Facebook, Instagram and Pinterest, as well as email and video communications. Using animated GIFs ${String.fromCharCode(8212)} like "Welcome Home," and images or video within words ${String.fromCharCode(8212)} will help bring the brand to life.`,
      text2: `While social media engagement is crucial, reaching prospects and cardholders as they browse the web ${String.fromCharCode(8212)} especially MySynchrony.com ${String.fromCharCode(8212)}  or on their phones reinforces the brand and keeps the Synchrony HOME program top-of-mind. Banner ads should focus primarily on captivating imagery, complemented by succinct, relevant and aspirational messaging related to the brand and/or campaign.`
    },
    bannerAdsNative: [
      {
        zillowBanner: {
          header: `Zillow`,
          headline: `25 characters`,
          subHeader: `70 characters`,
          cta: `15 characters`,
          sizes: [
            `828 x 372 pixels (Zillow desktop)`,
            `220-pixels-wide logo (transparent background)`
          ]
        },
        truliaBanner: {
          header: `Trulia`,
          headline: `25 characters`,
          subHeader: `70 characters`,
          cta: `15 characters`,
          sizes: [
            `828 x 372 pixels (Trulia desktop)`,
            `220-pixels-wide logo (transparent background)`
          ]
        },
        nativeBanner: {
          header: `Native`,
          headline: `90 characters`,
          subHeader: `140 characters`,
          cta: `25 characters`,
          sizes: [
            `1242 x 558 pixels (Trulia desktop)`,
            `390 x 390 pixels (Trulia mobile)`,
            `244-pixels-wide logo (transparent background)`
          ]
        }
      }
    ],
    bannerAdsDisplay: [
      {
        nativeSearch: {
          header: `Native Search`,
          copy: `125 Characters`,
          sizes: [
            `828 x 372 pixels`,
            `220-pixels-wide logo (transparent background)`
          ]
        },
        displayBanners: {
          header: `Display Banners`,
          sizes: [
            `300 x 250 pixels (max. copy: 90 characters)`,
            `300 x 600 pixels (max. copy: 125 characters)`,
            `160 x 600 pixels (max. copy: 90 characters)`,
            `728 x 90 pixels (max. copy: 45 characters)`,
            `320 x 50 pixels (max. copy: 45 characters)`,
            `300 x 50 pixels (max. copy: 45 characters)`,
            `970 x 250 pixels (max. copy: 125 characters)`,
            `970 x 550 pixels (max. copy: 125 characters)`
          ]
        }
      }
    ],
    mobileAppBanners: [
      {
        header: `Mobile App Banners`,
        subHeader: `(all banners must be under 1MB PNG/JPEG)`,
        sizes: [
          `Top Banner: 1242 x 874 pixels (max. copy: 75 characters; 53 pixels padding around)`,
          `Middle Banner: 1242 x 528 pixels (max. copy: 30 characters; 53 pixels padding around)`,
          `Bottom Banner: 1242 x 528 pixels (max. copy: 290 characters; 53 pixels padding around)`
        ]
      }
    ],
    consumerCenterBanners: [
      {
        header: `Consumer Center Banners`,
        subHeaders: [`Banner`, `Brick`],
        sizes: [
          `2568 x 780 pixels (max. copy: 350 characters)`,
          `1242 x 780 pixels (max. copy: 300 characters)`
        ]
      }
    ],
    mySyfBanners: [
      {
        header: `My Syf Banners`,
        subHeaderA: `Hero A`,
        subHeaderB: `Hero B`,
        subHeaderC: `Hero C`,
        sizes1: [
          `1400 x 933 pixels`,
          `Title Copy: max. 32 characters`,
          `Body Copy: max. 129 characters`
        ],
        sizes2: [
          `Call to Action Copy: max 31 characters`,
          `Call to Action URL: Campaign or website link`,
          `Duration: 1 month max run time`
        ],
        sizes3: [
          `300 x 250 pixels`,
          `Title Copy: max. 41 characters`,
          `Body Copy: max. 113 characters`
        ],
        sizes4: [
          `Call to Action 1 (Left) Copy: max 7 characters`,
          `Call to Action 1 URL (Left): Campaign or website link`,
          `Call to Action 2 (Right) Copy: max. 7 characters`,
          `Call to Action 2 URL (Right): Website, disclosures PDF, or retail application link`
        ],
        sizes5: [
          `1400 x 933 pixels`,
          `Title Copy: max. 32 characters`,
          `Body Copy: max. 129 characters`
        ],
        sizes6: [
          `Call to Action Copy: max 31 characters`,
          `Call to Action URL: Campaign or website link`,
          `Duration: 1 month max run time`
        ],
        image: `/images/home_fpo-banner-ad-image.jpg`
      }
    ]
  }
};

export const content = {
  guideIntroPage,
  copyGuidanceTemp,
  headerPages,
  theBrand,
  brandElements,
  digitalAssets
};
