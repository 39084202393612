import React from "react";
// import { Parallax, ParallaxLayer } from 'react-spring/renderprops-addons';
import styles from "../../SocialMediaSection.module.scss";
import TitleContentLockup from "components/global/TitleContentLockup";

const OrganicFacebook = ({ content }) => {
  // let parallax;
  return (
    // <Parallax scrolling={false} pages={1} ref={ref => parallax = ref}>
      <div className={styles.container}>
      <div className={styles.innerContainer} offset={0} speed={0}>
        {/* <ParallaxLayer offset={1} speed={.5} style={{ backgroundImage: '/images/home_social-images-background.png' background }} /> */}

          <TitleContentLockup
            title={content.titleLockup.title}
            subTitle={content.titleLockup.subTitle}
            text={content.titleLockup.text}
          />
        <div className={styles.infoContainer}>
          <h3 className={styles.header}>
            <img src={content.facebookIcon} alt="Facebook" /> Facebook
          </h3>
          <p className={styles.text}>
            This platform offers an opportunity to share both content and
            visuals to help promote the Synchrony HOME brand.
          </p>
          <ul className={styles.list}>
            <li>Use high-quality images/videos that are clear and engaging.</li>
            <li>
              <a href="https://www.facebook.com/ads/tools/text_overlay" target="_blank" rel="noopener noreferrer" className={styles.link}>
                No more than 20% of the image should contain text.
              </a>
            </li>
            <li>The ideal length for each post is 120 characters or less.</li>
            <li>
              Image:{" "}
              <span className={styles.thin}>
                Size:1080 x 1080 pixels; Ratio: 1:1
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    // </Parallax>
  );
};

export default OrganicFacebook;
