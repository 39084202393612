import React from "react";
import { useInView } from "react-intersection-observer";
import styles from "./TitleContentLockup.module.scss";
import PathLockup from "../PathLockup";

const TitleContentLockup = props => {
  const [ref, inView] = useInView({
    threshold: .5
  });

  return (
    <div className={styles.infoLockup} ref={ref}>
      <div className={styles.textContainer}>
        <span className={styles.header1}>{props.title1}</span>
        <span className={styles.header2}>{props.title2}</span>
        <span className={styles.header} id={inView ? styles.dashEffect : ''}>{props.title}</span>
        <PathLockup {...props} />
        <p className={styles.textLockup}>{props.text}</p>
        <p className={styles.textLockup}>{props.text1}</p>
        <p className={styles.textLockup}>{props.text2}</p>
        <p className={styles.textLockup}>{props.text3}</p>
        <h3 className={styles.subTitle}>{props.subTitle}</h3>
        <ul className={styles.list}>
          <li>{props.listItem1}</li>
          <li>{props.listItem2}</li> 
        </ul>
      </div>
    </div>
  );
};

export default TitleContentLockup;
