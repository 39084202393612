import React from "react";
import styles from "./BrandPositioningPage.module.scss";
import TitleContentLockup from "components/global/TitleContentLockup";

const BrandPositioningPage = ({ content }) => {
  return (
    <div className={styles.container}>
      <div className={styles.innerContainer}>
        <TitleContentLockup
          title1={content.titleLockup.title1}
          title={content.titleLockup.title}
          path={content.titleLockup.path}
          text={content.titleLockup.text}
        />
        <div className={styles.infoContainer}>
          <h3 className={styles.header}>{content.bodyHeader}</h3>
          <p>{content.bodyText}</p>
        </div>
      </div>
    </div>
  );
};

export default BrandPositioningPage;
