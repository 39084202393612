import React from "react";
import styles from "../../SocialMediaSection.module.scss";
import TitleContentLockup from "components/global/TitleContentLockup";

const PaidInstagram = ({ content }) => {
  return (
    <div className={styles.container}>
      <div className={styles.innerContainer}>
        <TitleContentLockup
          title={content.titleLockup.title}
          subTitle={content.titleLockup.subTitle}
          text={content.titleLockup.text}
        />
        <div className={styles.infoContainer}>
          <h3 className={styles.header}>
            <img src={content.instagramIcon} alt="Instagram" /> Instagram
          </h3>
          <p className={styles.text}>
            Ads on Instagram will serve the same purpose as Facebook, but should
            be tailored to take advantage of striking imagery, or videos.
            Visible character count is extremly limited.
          </p>
          <ul className={styles.list}>
            <li>
              Post must include primary text, image/video, website URL, and
              button.
            </li>
            <li>
              Copy:{" "}
              <span className={styles.thin}>
                100 characters will be visible in the feed
              </span>
            </li>
            <li>
              <a href="https://www.facebook.com/ads/tools/text_overlay" target="_blank" rel="noopener noreferrer" className={styles.link}>
                No more than 20% of the image should contain text.
              </a>
            </li>
            <li>
              Including a fake button in the image of the post is allowed, but
              not recommended.
            </li>
            <li>
              Image:{" "}
              <span className={styles.thin}>
                Size:1080 x 1080 pixels; Ratio: 1:1
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default PaidInstagram;
