import React from "react";
import { useInView } from "react-intersection-observer";
import {useSpring, animated} from 'react-spring'
import styles from "./TitlePage.module.scss";
import { useWindowSize } from "../../utils/helpers";

const TitlePage = () => {
  const size = useWindowSize();
  const [ref, inView] = useInView({
    threshold: .1
  });
  const [animate, set] = useSpring(() => ({opacity: 1, 'left':0, position: 'relative'}))
    set({'left': inView ? 0 : -1000})
  const [mobileAnimate, setMobile] = useSpring(() => ({ opacity: 0 }));
    setMobile({ opacity: inView ? 1 : 0 , delay: 500});
  return (
    <div className={styles.container} ref={ref}> 
      <div className={styles.imgContainer}>
        <div className={styles.textContainer}>
            <animated.div style={size.width > 1000 ? animate : mobileAnimate} className={styles.textInner}>
              <h2 className={styles.title}>
                Synchrony HOME&trade;<br/>Credit Card Program
              </h2>
              <h3 className={styles.subTitle}>Brand Style Guide</h3>
            </animated.div>
        </div>
      </div>
    </div>  
  );
};

export default TitlePage;
