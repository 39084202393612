import React, { Component } from "react";
import styles from "./ColorPalettePage.module.scss";
import TitleContentLockup from "components/global/TitleContentLockup";
import PageNumber from "components/global/PageNumber";
import ColorControls from "./ColorControls";

class ColorPalettePage extends Component {
  constructor(props) {
    super(props);

    this.copy = this.copy.bind(this);
    this.switch = this.switch.bind(this);
  }

  async copy(colorRef) {
    if (navigator.clipboard) {
      const success = await navigator.clipboard
        .writeText(this.refs[colorRef].innerText)
        .then(
          () => {
            return true;
          },
          () => {
            return false;
          }
        );
      return success;
    } else {
      const textArea = document.createElement("textarea");
      textArea.value = this.refs[colorRef].innerText;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand("copy");
      textArea.remove();
      return true;
    }
  }

  switch(value, colorRef) {
    this.refs[colorRef].innerText = value;
  }
  render() {
    let props = this.props;

    return (
      <div className={styles.container}>
        <div className={styles.innerContainer}>
          <TitleContentLockup
            title={props.content.titleLockup.title}
            text={props.content.titleLockup.text}
          />
          <div className={styles.infoContainer}>
            <div className={styles.colorWrapper}>
              <div className={styles.ltTeal} id={styles.colorBlock}>
                {/* These spans throughout are still giving the value to the copy function so it will remain, but always be hidden */}
                <span className={styles.hidden} id="ltTeal" ref="ltTeal">
                  #58A7AF
                </span>
                <ColorControls
                  copy={this.copy}
                  switch={this.switch}
                  colorRef="ltTeal"
                  header="Turquoise"
                  colors={{
                    hex: "#58A7AF",
                    pms: "PMS 7709C",
                    cmyk: "CMYK 66.18.31.0",
                    rgb: "RGB 88.167.175"
                  }}
                />
              </div>
              <div className={styles.teal} id={styles.colorBlock}>
                <span className={styles.hidden} id="teal" ref="teal">
                  #34657F
                </span>
                <ColorControls
                  copy={this.copy}
                  switch={this.switch}
                  colorRef="teal"
                  header="Teal"
                  // activeColor={this.refs[colorRef].innerText}
                  colors={{
                    hex: "#34657F",
                    pms: "PMS 7699",
                    cmyk: "CMYK 73.13.0.57",
                    rgb: "RGB 52.101.127"
                  }}
                />
              </div>
              <div className={styles.gold} id={styles.colorBlock}>
                <span className={styles.hidden} id="gold" ref="gold">
                #FBC600
                </span>
                <ColorControls
                  copy={this.copy}
                  switch={this.switch}
                  colorRef="gold"
                  inverted={true}
                  header="Synchrony Gold"
                  colors={{
                    hex: "#FBC600",
                    pms: "PMS 7579C",
                    cmyk: "CMYK 0.22.100.2",
                    rgb: "RGB 251.198.0"
                  }}
                />
              </div>
              <div className={styles.darkGrey} id={styles.colorBlock}>
                <span className={styles.hidden} id="dkGrey" ref="dkGrey">
                  #3B3C43
                </span>
                <ColorControls
                  copy={this.copy}
                  switch={this.switch}
                  colorRef="dkGrey"
                  header="Charcoal Gray"
                  colors={{
                    hex: "#3B3C43",
                    pms: "PMS Cool Gray 11C",
                    cmyk: "CMYK 44.34.22.77",
                    rgb: "RBG 59.60.57"
                  }}
                />
              </div>
              <div className={styles.ltGrey} id={styles.colorBlock}>
                <span className={styles.hidden} id="ltGrey" ref="ltGrey">
                  #94969A
                </span>
                <ColorControls
                  copy={this.copy}
                  switch={this.switch}
                  colorRef="ltGrey"
                  header="Steel Gray"
                  colors={{
                    hex: "#94969A",
                    pms: "PMS Cool Gray 7C",
                    cmyk: "CMYK 20.14.12.40",
                    rgb: "RGB 148.150.154"
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <PageNumber
            sectionTitle={props.sectionTitle}
            pageNumber={props.content.pageNumber}
          />
      </div>
    );
  }
}

export default ColorPalettePage;
