import React from "react";
import styles from "../BannerAdsSection.module.scss";
import TitleContentLockup from "components/global/TitleContentLockup";

const BannerAdsConsumerPage = ({ content }) => {
  return (
    <div className={styles.container}>
      <div className={styles.innerContainer}>
        <TitleContentLockup title={content.titleLockup.title} />
        <div className={styles.columnContainer}>
          {content.consumerCenterBanners.map((type, idx) => {
            let key = "item-key-" + idx++;
            return (
              <div key={key}>
                <h3 className={styles.header}>{type.header}</h3>
                <span className={styles.subHeader}>{type.subHeaders[0]}:</span>
                <ul className={styles.list}>
                  <li className={styles.item}>{type.sizes[0]}</li>
                </ul>
                <span className={styles.subHeader}>{type.subHeaders[1]}:</span>
                <ul className={styles.list}>
                  <li className={styles.item}>{type.sizes[1]}</li>
                </ul>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default BannerAdsConsumerPage;
