import React from "react";
import { useSpring, animated } from "react-spring";
import { Link } from "react-scroll";
import styles from "./MobileMenu.module.scss";
import {
  brandScroll,
  elementsScroll,
  assetsScroll,
  contactScroll
} from "utils/helpers";

const MobileMenu = ({ toggle, closeMobileMenu }) => {
  const [animateMain, set] = useSpring(() => ({ height: "0vh" }));
  set({ height: toggle ? "55vh" : "0vh" });
  const [animateInner] = useSpring(() => ({
    to: { right: 0 },
    delay: 500,
    from: { right: -2000 }
  }));
  const [animateButton] = useSpring(() => ({
    to: { opacity: 1 },
    delay: 1000,
    from: { opacity: 0 }
  }));

  return (
    <animated.div className={styles.container} id="navbar" style={animateMain}>
      <div className={styles.content}>
        <animated.div className={styles.innerContent} style={animateInner}>
          <div className={styles.links}>
            <Link
              to="brand-section"
              onClick={() => {
                brandScroll();
                closeMobileMenu();
              }}
              spy={true}
              className={styles.link}
            >
              The Brand
            </Link>
            <Link
              to="elements-section"
              onClick={() => {
                elementsScroll();
                closeMobileMenu();
              }}
              spy={true}
              className={styles.link}
            >
              Brand Elements
            </Link>
            <Link
              to="assets-section"
              onClick={() => {
                assetsScroll();
                closeMobileMenu();
              }}
              spy={true}
              className={styles.link}
            >
              Digital Assets
            </Link>
            <Link
              to="contact-section"
              onClick={() => {
                contactScroll();
                closeMobileMenu();
              }}
              spy={true}
              className={styles.link}
            >
              Contact Us
            </Link>
          </div>
          <animated.div style={animateButton}>
            <span className={styles.button}>
              <a
                href="https://look-listen-public.s3-us-west-2.amazonaws.com/homebrandguide/SYC-Brand-Guidelines-052020.pdf"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.buttonInner}
              >
                Download with Inka&trade;
              </a>
            </span>
          </animated.div>
        </animated.div>
      </div>
    </animated.div>
  );
};

export default MobileMenu;
