import React, { useState } from "react";
import styles from "./VoiceTonePage.module.scss";
import TitleContentLockup from "components/global/TitleContentLockup";
import audio from "./audio.aac";

const VoiceTonePage = ({ content }) => {
  const [playerActive, setplayerActive] = useState(true);

  const toggle = () => {
    setplayerActive(!playerActive);
    let audio = document.getElementById("audio");
    playerActive ? audio.play() : audio.pause();
    if(audio.ended) {
      setplayerActive(true)
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.innerContainer}>
        <TitleContentLockup title={content.titleLockup.title} />
        <div className={styles.infoContainer}>
          <p>{content.bodyText}</p>
          <audio id="audio">
            <source src={audio} type="audio/mp3" />
          </audio>
          <button className={styles.button} onClick={toggle}>
            {playerActive ? (
              <img
                src="/images/svg/play-icon.svg"
                alt="Play Icon"
                className={styles.icon}
              />
            ) : (
              <img
                src="/images/svg/pause-icon.svg"
                alt="Pause Icon"
                className={styles.icon}
              />
            )}
            PLAY VOICE &amp; TONE EXAMPLE
          </button>
        </div>
      </div>
    </div>
  );
};

export default VoiceTonePage;
