import React from "react";
import { useInView } from "react-intersection-observer";
import { useSpring, animated } from "react-spring";
import styles from "./SectionHeaderPage.module.scss";

const SectionHeaderPage = props => {
  const [ref, inView] = useInView({
    threshold: 0.2 
  });

  const [animate, set] = useSpring(() => ({ opacity: 1, marginRight: -1000 }));
  set({ marginRight: inView ? 0 : -1000, delay: 500 });

  return (
    <div
      className={styles.imgContainer}
      style={{ backgroundImage: `url(${props.backgroundImg})` }}
      ref={ref}
    >
      <animated.div className={styles.textContainer} style={animate}>
        <div className={styles.textInner}>
          <h2 className={styles.title}>{props.title}</h2>
        </div>
      </animated.div>
    </div>
  );
};

export default SectionHeaderPage;
