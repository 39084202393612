import React from "react";
import styles from "./DigitalAssetsIntroPage.module.scss";
import TitleContentLockup from "components/global/TitleContentLockup";
import PageNumber from "components/global/PageNumber";

const DigitalAssetsIntroPage = ({ content, sectionTitle }) => {
  return (
    <div>
    <div className={styles.container}>
      <TitleContentLockup
        title={content.titleLockup.title}
        path={content.titleLockup.path}
        text={content.titleLockup.text}
      />
      <div className={styles.infoContainer}>
        <h3 className={styles.header}>B2B Assets</h3>
        <ul className={styles.list}>
          <li>
            <a
              href="https://synchronyhomepartner.com/"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.link}
            >
              SynchronyHomePartner.com
            </a>
          </li>
          <li>
            <a
              href="https://www.synchronybusiness.com/"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.link}
            >
              SynchronyBusiness.com
            </a>
          </li>
        </ul>
        <h3 className={styles.header}>B2C Assets</h3>
        <ul className={styles.list}>
          <li>
            <a
              href="https://www.mysynchrony.com/home/cardholder_index.html"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.link}
            >
              MySynchrony Cardholder
            </a>
          </li>
          <li>
            <a
              href="https://www.mysynchrony.com/home/retailers.html"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.link}
            >
              Locator Tool
            </a>
          </li>
          <li>
            <a
              href="https://www.mysynchrony.com/home/cardholder_index.html#special-offers"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.link}
            >
              Home Offers &amp; Tips
            </a>
          </li>
          <li>
            <a
              href="https://www.mysynchrony.com/"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.link}
            >
              MySynchrony Prospect
            </a>
          </li>
          <li>
            <a
              href="https://www.mysynchrony.com/home/electronics.html"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.link}
            >
              Electronics Page
            </a>
          </li>
          <li>
            <a
              href="https://www.mysynchrony.com/home/furnishing.html"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.link}
            >
              Furnishings Page
            </a>
          </li>
          <li>
            <a
              href="https://www.mysynchrony.com/flooring.html"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.link}
            >
              Flooring Page
            </a>
          </li>
        </ul>
      </div>
      </div>
      <PageNumber sectionTitle={sectionTitle} pageNumber={content.pageNumber} />
    </div>
  );
};

export default DigitalAssetsIntroPage;
