import React from "react";
import styles from "./SubSectionHeader.module.scss";

const SubSectionHeader = props => {
  return (
    <div className={styles.infoLockup}>
      <p className={styles.textLockup}>
        Synchrony HOME Credit Card Brand Mark (cont.)
      </p>
    </div>
  );
};

export default SubSectionHeader;
