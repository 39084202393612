import React from 'react';
import { Element } from 'react-scroll';
import SectionHeaderPage from 'components/global/SectionHeaderPage';
import InspirationPage from './InspirationPage';
import ColorPalettePage from './ColorPalettePage';
import FontPage from './FontPage';
import IconographyPage from './IconographyPage';
import PhotographyPatternPage from './PhotographyPatternPage';
import BrandMarkSection from './BrandMarkSection';
import CardArtPage from './CardArtPage';
import WelcomeHomePage from './WelcomeHomePage';
import DreamHomePage from './DreamHomePage';
import GoldCorePage from './GoldCorePage';

const BrandElementsSection = ({ content }) => {
	return (
		<Element id='elements-section' name='elements-section'>
			<div >
				<SectionHeaderPage title={content.headerPages.brandElements.title} backgroundImg={content.headerPages.brandElements.backgroundImg}/>
				<InspirationPage content={content.brandElements.inspirationPage} sectionTitle={content.brandElements.sectionTitle} />
				<ColorPalettePage content={content.brandElements.colorPalette} sectionTitle={content.brandElements.sectionTitle} />
				<FontPage content={content.brandElements.fontPage} sectionTitle={content.brandElements.sectionTitle} />
				<IconographyPage content={content.brandElements.iconographyPage} sectionTitle={content.brandElements.sectionTitle} />
				<PhotographyPatternPage content={content.brandElements.photographyPatternPage} sectionTitle={content.brandElements.sectionTitle} />
				<BrandMarkSection content={content} sectionTitle={content.brandElements.sectionTitle} />
				<CardArtPage content={content.brandElements.cardArtPage} sectionTitle={content.brandElements.sectionTitle} />
				<WelcomeHomePage content={content.brandElements.welcomeHomePage} sectionTitle={content.brandElements.sectionTitle} />
				<DreamHomePage content={content.brandElements.dreamHomePage} sectionTitle={content.brandElements.sectionTitle} />
				<GoldCorePage content={content.brandElements.goldCorePage} sectionTitle={content.brandElements.sectionTitle} />
			</div>
		</Element>
	);
};

export default BrandElementsSection;