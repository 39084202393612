import React from "react";
import styles from "../BannerAdsSection.module.scss";
import TitleContentLockup from "components/global/TitleContentLockup";

const BannerAdsNativePage = ({ content }) => {
  return (
    <div className={styles.containerGrey}>
      <div className={styles.innerContainer}>
        <TitleContentLockup title={content.titleLockup.title} />
          {content.bannerAdsNative.map((type,idx) => {
                let key = 'item-key-' + idx++;
            return (
              <div className={styles.columnContainer} key={key}>
                <div className={styles.column}>
                  <h3 className={styles.header} >
                    {type.zillowBanner.header}
                    <br />
                    Banner
                  </h3>
                  <span className={styles.subHeader}>Copy:</span>
                  <ul className={styles.list}>
                    <li >
                      Headline:
                      <br />
                      {type.zillowBanner.headline}
                    </li>
                    <li > 
                      Subheader:
                      <br />
                      {type.zillowBanner.subHeader}
                    </li>
                    <li >
                      CTA:
                      <br />
                      {type.zillowBanner.cta}
                    </li>
                  </ul>
                  <span className={styles.subHeader}>Sizes:</span>
                  <ul className={styles.list}>
                    {type.zillowBanner.sizes.map((size, secondIdx) => {
                      let secondKey = 'inner-item-key-' + secondIdx++;
                      return <li key={secondKey}>{size}</li>;
                    })}
                  </ul>
                </div>
                <div className={styles.column}>
                  <h3 className={styles.header}>
                    {type.truliaBanner.header}
                    <br />
                    Banner
                  </h3>
                  <span className={styles.subHeader}>Copy:</span>
                  <ul className={styles.list}>
                    <li >
                      Headline:
                      <br />
                      {type.truliaBanner.headline}
                    </li>
                    <li >
                      Subheader:
                      <br />
                      {type.truliaBanner.subHeader}
                    </li>
                    <li >
                      CTA:
                      <br />
                      {type.truliaBanner.cta}
                    </li>
                  </ul>
                  <span className={styles.subHeader}>Sizes:</span>
                  <ul className={styles.list}>
                    {type.truliaBanner.sizes.map((size, thirdIdx) => {
                      let thirdKey = 'second-inner-item-key-' + thirdIdx++;
                      return <li key={thirdKey}>{size}</li>;
                    })}
                  </ul>
                </div>
                <div className={styles.column}>
                  <h3 className={styles.header} >
                    {type.nativeBanner.header}
                    <br />
                    Banner
                  </h3>
                  <span className={styles.subHeader}>Copy:</span>
                  <ul className={styles.list}>
                    <li >
                      Headline:
                      <br />
                      {type.nativeBanner.headline}
                    </li>
                    <li >
                      Subheader:
                      <br />
                      {type.nativeBanner.subHeader}
                    </li>
                    <li >
                      CTA:
                      <br />
                      {type.nativeBanner.cta}
                    </li>
                  </ul>
                  <span className={styles.subHeader}>Sizes:</span>
                  <ul className={styles.list}>
                    {type.nativeBanner.sizes.map((size, fourthIdx) => {
                      let fourthKey = 'third-inner-item-key-' + fourthIdx++;
                      return <li key={fourthKey}>{size}</li>;
                    })}
                  </ul>
                </div>
                </div>
            );
          })}
      </div>
    </div>
  );
};

export default BannerAdsNativePage;
