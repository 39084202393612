import React from "react";
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonNext,
  ButtonBack,
  DotGroup
} from "pure-react-carousel";
import { useInView } from "react-intersection-observer";
import { checkKey, useWindowSize } from "utils/helpers";
import "pure-react-carousel/dist/react-carousel.es.css";
import carouselStyles from "../../../TheBrandSection/BrandPositioningSection/BrandPositioningSection.module.scss";
import OrganicFacebook from "./OrganicFacebook";
import OrganicInstagram from "./OrganicInstagram";
import OrganicPinterest from "./OrganicPinterest";
import PageNumber from "components/global/PageNumber";

const SocialMediaOrganic = ({ content, sectionTitle }) => {
  const size = useWindowSize();
  document.onkeydown = e =>
    checkKey(e, "organicLeftButton", "organicRightButton");
  const [ref, inView] = useInView({
    threshold: 0.5
  });

  return (
    <div ref={ref}>
      {size.width > 1200 ? (
        <>
          <CarouselProvider
            naturalSlideWidth={125}
            naturalSlideHeight={50}
            totalSlides={3}
            infinite={true}
            className={carouselStyles.container}
          >
            <Slider
              className={carouselStyles.container}
              classNameAnimation={carouselStyles.transitionEffect}
            >
              <Slide index={0}>
                <OrganicFacebook
                  content={content.socialMediaOrganic}
                  sectionTitle={sectionTitle}
                />
                <ButtonNext
                  id="organicRightButton"
                  className={carouselStyles.button}
                  disabled={!inView}
                >
                  <img
                    src="/images/svg/right-arrow-icon-light.svg"
                    alt="Next Arrow"
                  />
                </ButtonNext>
              </Slide>
              <Slide index={1}>
                <OrganicInstagram
                  content={content.socialMediaOrganic}
                  sectionTitle={sectionTitle}
                />
                <ButtonBack className={carouselStyles.leftButton}>
                  <img
                    src="/images/svg/left-arrow-icon-light.svg"
                    alt="Back Arrow"
                  />
                </ButtonBack>
                <ButtonNext className={carouselStyles.button}>
                  <img
                    src="/images/svg/right-arrow-icon-light.svg"
                    alt="Next Arrow"
                  />
                </ButtonNext>
              </Slide>
              <Slide index={2}>
                <OrganicPinterest
                  content={content.socialMediaOrganic}
                  sectionTitle={sectionTitle}
                />
                <ButtonBack className={carouselStyles.leftButton}>
                  <img
                    src="/images/svg/left-arrow-icon-light.svg"
                    alt="Back Arrow"
                  />
                </ButtonBack>
                {/* <ButtonNext className={carouselStyles.button}>
            <img
              src="/images/svg/right-arrow-icon-light.svg"
              alt="Next Arrow"
            />
          </ButtonNext> */}
              </Slide>
            </Slider>
            <div className={carouselStyles.dotGroup}>
              <DotGroup
                className={carouselStyles.dots}
                showAsSelectedForCurrentSlideOnly={true}
              />
            </div>
            <ButtonBack
              className={carouselStyles.hidden}
              id="organicLeftButton"
              disabled={!inView}
            />
          </CarouselProvider>
          <PageNumber
            sectionTitle={sectionTitle}
            pageNumber={content.socialMediaOrganic.pageNumber}
          />
        </>
      ) : (
        <>
          <OrganicFacebook
            content={content.socialMediaOrganic}
            sectionTitle={sectionTitle}
          />
          <OrganicInstagram
            content={content.socialMediaOrganic}
            sectionTitle={sectionTitle}
          />
          <OrganicPinterest
            content={content.socialMediaOrganic}
            sectionTitle={sectionTitle}
          />
        </>
      )}
    </div>
  );
};

export default SocialMediaOrganic;
