import React from "react";
import styles from "./BrandMarkIntroPage.module.scss";
import TitleContentLockup from "components/global/TitleContentLockup";
import DisclaimerFooter from "components/global/DisclaimerFooter";
import PageNumber from "components/global/PageNumber";
import DownloadImage from "../../../global/DownloadImage/DownloadImage";

const BrandMarkIntroPage = ({ content, sectionTitle }) => {
  return (
    <div className={styles.container}>
      <div className={styles.innerContainer}>
        <TitleContentLockup
          title1={content.titleLockup.title1}
          title2={content.titleLockup.title2}
          title={content.titleLockup.title}
          path={content.titleLockup.path}
          text={content.titleLockup.text}
          text2={content.titleLockup.text2}
        />
        <div className={styles.infoContainer}>
          <DownloadImage
            downloadLink='images/download/SYF_HOME_Brand_Mark.zip'
            fileName="SYF_HOME_Brand_Mark.zip"
            imageLink={content.image}
            class="grid-image"
          />
        </div>
      </div>
      <PageNumber
          sectionTitle={sectionTitle}
          pageNumber={content.pageNumber}
        />
      <DisclaimerFooter footer={content.disclaimer} />
    </div>
  );
};

export default BrandMarkIntroPage;
