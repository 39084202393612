import React from "react";
import { Element } from "react-scroll";
import SectionHeaderPage from "components/global/SectionHeaderPage";
import DigitalAssetsIntroPage from "./DigitalAssetsIntroPage";
import SocialMediaSection from "./SocialMediaSection";
import BannerAdsSection from "./BannerAdsSection";

const DigitalAssetsSection = ({ content }) => {
  return (
    <Element id="assets-section" name="assets-section">
      <div>
        <SectionHeaderPage
          title={content.headerPages.digitalAssets.title}
          backgroundImg={content.headerPages.digitalAssets.backgroundImg}
        />
        <DigitalAssetsIntroPage
          content={content.digitalAssets.digitalAssetsIntro}
          sectionTitle={content.digitalAssets.sectionTitle}
        />
        <SocialMediaSection
          content={content.digitalAssets.socialMediaSection}
          sectionTitle={content.digitalAssets.sectionTitle}
        />
        <BannerAdsSection
          content={content.digitalAssets.bannerAdsSection}
          sectionTitle={content.digitalAssets.sectionTitle}
        />
      </div>
    </Element>
  );
};

export default DigitalAssetsSection;
