import React from "react";
import styles from "./BrandSpacingPage.module.scss";
import PageNumber from "components/global/PageNumber";
import SubSectionHeader from "components/global/SubSectionHeader";

const BrandSpacingPage = ({ content, sectionTitle }) => {
  return (
    <div className={styles.container}>
      <SubSectionHeader />
      <div className={styles.innerContainer}>
        <div className={styles.infoContainer}>
          <img
            src={content.images[1]}
            className={styles.icons}
            alt="Synchrony HOME icons"
          />
          <h3 className={styles.header}>{content.headers[0]}</h3>
          <ul className={styles.list}>
            <li>
              The <span className={styles.bold}>largest</span> size the brand
              mark can be is 3&rdquo; wide on printed materials or 350px wide on
              digital media.
            </li>
            <li>
              The <span className={styles.bold}>smallest</span> size the brand
              mark can be is 1&rdquo; wide on printed materials or 60px wide on
              digital media.
            </li>
            <li>
              <span className={styles.bold}>Exceptions:</span> The brand mark
              can be larger on signage and for trade show applications.
            </li>
          </ul>
        </div>
        <div className={styles.infoContainer}>
          <img
            src={content.images[0]}
            className={styles.icons}
            alt="Synchrony HOME icons"
          />
          <h3 className={styles.header}>{content.headers[1]}</h3>
          <p className={styles.text}>
            A safe zone or negative space needs to be maintained around the
            brand mark. It should be equal to or greater than the width of the
            Pillars in the Synchrony HOME brand mark, as shown.
          </p>
        </div>
      </div>
      <PageNumber
          sectionTitle={sectionTitle}
          pageNumber={content.pageNumber}
        />
    </div>
  );
};

export default BrandSpacingPage;
