import React from "react";
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
  DotGroup
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import { useInView } from "react-intersection-observer";
import { checkKey, useWindowSize } from "utils/helpers";
import carouselStyles from "../../../TheBrandSection/BrandPositioningSection/BrandPositioningSection.module.scss";
import PaidFacebook from "./PaidFacebook";
import PaidInstagram from "./PaidInstagram";
import PaidPinterest from "./PaidPinterest";
import PageNumber from "components/global/PageNumber";

const SocialMediaPaid = ({ content, sectionTitle }) => {
  const size = useWindowSize();
  document.onkeydown = e => checkKey(e, "paidLeftButton", "paidRightButton");
  const [ref, inView] = useInView({
    threshold: 0.6
  });

  return (
    <div ref={ref}>
      {size.width > 1200 ? (
      <>
      <CarouselProvider
        naturalSlideWidth={125}
        naturalSlideHeight={50}
        totalSlides={3}
        infinite={true}
        className={carouselStyles.container}
      >
        <Slider
          className={carouselStyles.container}
          classNameAnimation={carouselStyles.transitionEffect}
        >
          <Slide index={0}>
            <PaidFacebook
              content={content.socialMediaPaid}
              sectionTitle={sectionTitle}
            />
            <ButtonNext
              className={carouselStyles.button}
              id="paidRightButton"
              disabled={!inView}
            >
              <img
                src="/images/svg/right-arrow-icon-light.svg"
                alt="Next Arrow"
              />
            </ButtonNext>
          </Slide>
          <Slide index={1}>
            <PaidInstagram
              content={content.socialMediaPaid}
              sectionTitle={sectionTitle}
            />
            <ButtonBack className={carouselStyles.leftButton}>
              <img
                src="/images/svg/left-arrow-icon-light.svg"
                alt="Back Arrow"
              />
            </ButtonBack>
            <ButtonNext className={carouselStyles.button}>
              <img
                src="/images/svg/right-arrow-icon-light.svg"
                alt="Next Arrow"
              />
            </ButtonNext>
          </Slide>
          <Slide index={2}>
            <PaidPinterest
              content={content.socialMediaPaid}
              sectionTitle={sectionTitle}
            />
            <ButtonBack className={carouselStyles.leftButton}>
              <img
                src="/images/svg/left-arrow-icon-light.svg"
                alt="Back Arrow"
              />
            </ButtonBack>
          </Slide>
        </Slider>
        <div className={carouselStyles.dotGroup}>
          <DotGroup
            className={carouselStyles.dots}
            showAsSelectedForCurrentSlideOnly={true}
          />
        </div>
        <ButtonBack
          className={carouselStyles.hidden}
          id="paidLeftButton"
          disabled={!inView}
        />
      </CarouselProvider>
        <PageNumber
          sectionTitle={sectionTitle}
          pageNumber={content.socialMediaPaid.pageNumber}
        />
        </>
      ) : (
        <>
                    <PaidFacebook
              content={content.socialMediaPaid}
              sectionTitle={sectionTitle}
            />
                        <PaidInstagram
              content={content.socialMediaPaid}
              sectionTitle={sectionTitle}
            />
                        <PaidPinterest
              content={content.socialMediaPaid}
              sectionTitle={sectionTitle}
            />
        </>
      )}
    </div>
  );
};

export default SocialMediaPaid;
