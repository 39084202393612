import * as Scroll from "react-scroll";
import { useState, useEffect, useCallback } from "react";
import React from "react";

export const checkKey = function(e, id1, id2) {
  e = e || window.event;
  if (e.keyCode === 37) {
    e.preventDefault(); // Trigger the button element with a click
    document.getElementById(id1).click();
  } else if (e.keyCode === 39) {
    e.preventDefault(); // Trigger the button element with a click
    document.getElementById(id2).click();
  }
};

export const brandScroll = function() {
  let scroller = Scroll.scroller;

  scroller.scrollTo("brand-section", {
    duration: 3000,
    smooth: "easeInOutQuint",
    offset: 0 // Scrolls to element 0 pixels down the page to adjust for header height
  });
};

export const elementsScroll = function() {
  let scroller = Scroll.scroller;

  scroller.scrollTo("elements-section", {
    duration: 3000,
    smooth: "easeInOutQuint",
    offset: 0
  });
};

export const assetsScroll = function() {
  let scroller = Scroll.scroller;

  scroller.scrollTo("assets-section", {
    duration: 3000,
    smooth: "easeInOutQuint",
    offset: 0
  });
};
export const contactScroll = function() {
  let scroller = Scroll.scroller;

  scroller.scrollTo("contact-section", {
    duration: 3000,
    smooth: "easeInOutQuint",
    offset: 0
  });
};

//Setting up the functionality to remove/manage the notifcation in quicktips
export const useStateWithLocalStorage = localStorageKey => {
  const [value, setValue] = React.useState(
    JSON.parse(localStorage.getItem(localStorageKey)) || ""
  );

  React.useEffect(() => {
    localStorage.setItem(localStorageKey, JSON.stringify(value));
  }, [value, localStorageKey]);

  return [value, setValue];
};

//Hash the quick tips for a key to determine if there is a new message or not
export const stringToHash = string => {
  let hash = 0;
  let i;
  let char;

  if (string.length === 0) {
    return hash;
  }
  for (i = 0; i < string.length; i++) {
    char = string.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash;
  }

  return hash;
};

// Window sizing listener to read/disable animations
export const useWindowSize = () => {
  const isClient = typeof window === "object";

  // function getSize() {
  //   return {
  //     width: isClient ? window.innerWidth : undefined,
  //     height: isClient ? window.innerHeight : undefined
  //   };
  // }

  const getSizeCallback = useCallback(() => {
    return {
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined
    };
  }, [isClient]);

  const [windowSize, setWindowSize] = useState(getSizeCallback);

  useEffect(() => {
    if (!isClient) {
      return false;
    }

    function handleResize() {
      setWindowSize(getSizeCallback());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [getSizeCallback, isClient]); // Empty array ensures that effect is only run on mount and unmount

  return windowSize;
};
