import React from "react";
import styles from "./FontPage.module.scss";
import TitleContentLockup from "components/global/TitleContentLockup";
import PageNumber from "components/global/PageNumber";

const FontPage = ({ content, sectionTitle }) => {
  const alphabet = "a b c d e f g h i j k l m n o p q r s t u v w x y z";
  return (
    <div>
    <div className={styles.container}>
      <TitleContentLockup
        title={content.titleLockup.title}
        text={content.titleLockup.text}
      />
      <a
          href="/fonts/SynchronySansFonts.zip"
          download="SynchronySansFonts.zip" 
          target="_blank"
          rel="noopener noreferrer"
          id={styles.imgDownload}
        >
      <img
          className={styles.gridImage}
          src="images/svg/download-icon-white.svg"
          alt=""
        />
      <div className={styles.infoContainer} >
        <h3 className={styles.header}>
          <span>{content.header}</span>
        </h3>
          <ul className={styles.listThin} id={styles.list}>
            <li className={styles.regular}>Thin</li>
            <li>{alphabet}</li>
            <li className={styles.upperCase}>{alphabet}</li>
          </ul>
          <ul className={styles.regular} id={styles.list}>
            <li className={styles.regular}>Regular</li>
            <li>{alphabet}</li>
            <li className={styles.upperCase}>{alphabet}</li>
          </ul>
          <ul className={styles.listMed} id={styles.list}>
            <li className={styles.regular}>Medium</li>
            <li>{alphabet}</li>
            <li className={styles.upperCase}>{alphabet}</li>
          </ul>
          <ul className={styles.listBold} id={styles.list}>
            <li className={styles.regular}>Bold</li>
            <li>{alphabet}</li>
            <li className={styles.upperCase}>{alphabet}</li>
          </ul>
      </div>
      </a>
      </div>
      <PageNumber sectionTitle={sectionTitle} pageNumber={content.pageNumber} />
    </div>
  );
};

export default FontPage;
