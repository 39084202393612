import React from "react";
import { 
  CarouselProvider,
  Slider,
  Slide,
  ButtonNext,
  ButtonBack,
  DotGroup
} from "pure-react-carousel";
import { useInView } from "react-intersection-observer";
import "pure-react-carousel/dist/react-carousel.es.css";
import styles from "./BrandPositioningSection.module.scss";
import BrandPositioningPage from "./BrandPositioningPage";
import ValuePropositionPage from "./ValuePropositionPage";
import ValPropTipsPage from "./ValPropTipsPage";
import AdditionalBenefitsPage from "./AdditionalBenefitsPage";
import PageNumber from "components/global/PageNumber";
import { checkKey, useWindowSize } from "utils/helpers";

const BrandPositioningSection = ({ sectionTitle, content }) => {
  document.onkeydown = e => checkKey(e, "leftButton", "rightButton");
  const size = useWindowSize();
  const [ref, inView] = useInView({
    /* Optional options */
    threshold: 0
  });

  return (
    <div id="container" ref={ref}>
      {size.width > 1200 ? (
        <CarouselProvider
          naturalSlideWidth={125}
          naturalSlideHeight={50}
          totalSlides={4}
          infinite={true}
          className={styles.container}
        >
          <Slider
            className={styles.container}
            classNameAnimation={styles.transitionEffect}
          >
            <Slide index={0}>
              <BrandPositioningPage
                sectionTitle={sectionTitle}
                content={content.brandPage}
              />
              <ButtonNext
                className={styles.button}
                id="rightButton"
                disabled={!inView}
              >
                <img
                  src="/images/svg/right-arrow-icon-light.svg"
                  alt="Next Arrow"
                />
              </ButtonNext>
            </Slide>
            <Slide index={1}>
              <ValuePropositionPage
                sectionTitle={sectionTitle}
                content={content.valuePage}
              />
              <ButtonBack className={styles.leftButton}>
                <img
                  src="/images/svg/left-arrow-icon-light.svg"
                  alt="Back Arrow"
                />
              </ButtonBack>
              <ButtonNext className={styles.button}>
                <img
                  src="/images/svg/right-arrow-icon-light.svg"
                  alt="Next Arrow"
                />
              </ButtonNext>
            </Slide>
            <Slide index={2}>
              <ValPropTipsPage
                sectionTitle={sectionTitle}
                content={content.valPropTips}
              />
              <ButtonBack className={styles.leftButton}>
                <img
                  src="/images/svg/left-arrow-icon-light.svg"
                  alt="Back Arrow"
                />
              </ButtonBack>
              <ButtonNext className={styles.button}>
                <img
                  src="/images/svg/right-arrow-icon-light.svg"
                  alt="Next Arrow"
                />
              </ButtonNext>
            </Slide>
            <Slide index={3}>
              <AdditionalBenefitsPage
                sectionTitle={sectionTitle}
                content={content.benefitsPage}
              />
              <ButtonBack className={styles.leftButton}>
                <img
                  src="/images/svg/left-arrow-icon-light.svg"
                  alt="Back Arrow"
                />
              </ButtonBack>
            </Slide>
          </Slider>
          <div className={styles.dotGroup}>
            <DotGroup
              className={styles.dots}
              showAsSelectedForCurrentSlideOnly={true}
            />
          </div>
          <ButtonBack
            className={styles.hidden}
            disabled={!inView}
            id="leftButton"
          />
        </CarouselProvider>
      ) : (
        <div>
          <BrandPositioningPage
            sectionTitle={sectionTitle}
            content={content.brandPage}
          />
          <ValuePropositionPage
            sectionTitle={sectionTitle}
            content={content.valuePage}
          />
          <ValPropTipsPage
            sectionTitle={sectionTitle}
            content={content.valPropTips}
          />
          <AdditionalBenefitsPage
            sectionTitle={sectionTitle}
            content={content.benefitsPage}
          />
        </div>
      )}
      <PageNumber
        sectionTitle={sectionTitle}
        pageNumber={content.benefitsPage.pageNumber}
      />
    </div>
  );
};

export default BrandPositioningSection;
