import React from "react";

function getQuickTips(innerList, italic) {
  return [
    {
      title: <>Miscellaneous legal feedback</>,
      hashText: `Thousands of participating Synchrony HOME locations refers to the
      locations where cardholders get access to longer term promotional
      financing.`,
      items: [
        <>
          Disclosures must be present on marketing assets (if needed), or one
          click away.{" "}
          <span className={innerList}>
            Material terms must be present on the asset
          </span>
        </>,
        <>
          Only <span className={italic}>Apply Now</span> or{" "}
          <span className={italic}>Learn More</span> can be used as Calls to
          Action to lead to an application page.
        </>,
        <>
          <span className={italic}>
            Thousands of participating Synchrony HOME locations
          </span>{" "}
          refers to the locations where cardholders get access to longer term
          promotional financing.
        </>
      ]
    },
    {
      title: <>Miscellaneous legal feedback</>,
      hashText: `When referring to things you can buy with Synchrony HOME, refer to purchases or services.`,
      items: [
        <>
          Use <span className={italic}>help</span> in copy to refer to what the
          brand can do:
          <span className={italic}>
            {" "}
            The Synchrony HOME Credit Card can help make your dream home a
            reality
          </span>
          .
        </>,
        <>
          <span className={italic}>Again and again</span> can only be used to
          refer to using the card, not making purchases.
        </>,
        <>
          <span className={italic}>
            Enjoy Everyday Benefits Everywhere the Card is Accepted
          </span>{" "}
          only applies to the <span className={italic}>2% back</span> and{" "}
          <span className={italic}>6 months promo financing</span> parts of the
          value proposition.{" "}
          <span className={italic}>12-60 months promo financing</span> must be
          set apart in both design and copy.
        </>,
        <>
          When referring to things you can buy with Synchrony HOME, refer to
          <span className={italic}> purchases</span> or{" "}
          <span className={italic}>services</span>.
        </>
      ]
    }
  ];
}

export { getQuickTips };
