import React from "react";
import { useInView } from "react-intersection-observer";
import { useSpring, animated } from "react-spring";
import styles from "./PageNumber.module.scss";

const PageNumber = props => {
  const [ref, inView] = useInView({
    threshold: 0
  });

  const [animate, set] = useSpring(() => ({ opacity: 1 }));
  set({ opacity: inView ? 1 : 0, delay: 100 });
  return (
    <div className={styles.container}>
      <animated.div className={styles.innerContainer} style={animate}>
        <span className={styles.title} ref={ref}>{props.sectionTitle}</span>
        <span className={styles.number}>{props.pageNumber}</span>
      </animated.div>
    </div>
  );
};

export default PageNumber;
