import React from 'react';
import styles from '../../SocialMediaSection.module.scss';
import TitleContentLockup from 'components/global/TitleContentLockup';

const OrganicPinterest = ({content}) => {
	return (
		<div className={styles.container}>
			<div className={styles.innerContainer}>
				<TitleContentLockup title={content.titleLockup.title} subTitle={content.titleLockup.subTitle} text={content.titleLockup.text} />
				<div className={styles.infoContainer}>
          <h3 className={styles.header}><img src={content.pinterestIcon} alt='Facebook'/> Pinterest</h3>
					<p className={styles.text}>Think of Pinterest as a big inspiration board. Share and link to projects that customers have completed (or could complete) with the help of the Synchrony HOME Credit Card.</p>
					<ul className={styles.list}>
						<li>Consistent activity is better than adding several pins at once.</li>
						<li>Optimize your boards for Pinterest search.</li>
						<li>Add the &ldquo;save&rdquo; button to your website to make it more Pinterest-friendly.</li>
						<li>Copy: <span className={styles.thin}>Title: Up to 100 characters; Description: Up to 500 characters. First 50-60 will be visible in the feed.</span></li>
						<li>Image: <span className={styles.thin}>File type: PNG or JPEG; Max file size: 32 MB; Aspect ratio: 2:3 aspect ratio, or 1000 x 1500 pixels. Pins with an aspect ratio that&rsquo;s greater than 2:3 might get cut off in people&rsquo;s feeds.</span></li>
					</ul>
				</div>
			</div>
		</div>
	);
};

export default OrganicPinterest;