import React from "react";
import styles from "./ValPropTipsPage.module.scss";

const ValPropTipsPage = ({ content }) => { 
  return (
    <div className={styles.container}>
      <div className={styles.innerContainer}>
        <h3 className={styles.header}>{content.header}:</h3>
        <div className={styles.listContainer}>
          <h4 className={styles.subHeader}>{content.subHeaders[0]}:</h4>
          <ul className={styles.list}>
            <li>
              It is legally approved to use either{" "}
              <span className={styles.italic}>
                purchases of $299 or more</span> OR <span className={styles.italic}>purchases $299 or more
              </span>{" "}
              based an character counts and available space.
            </li>
            <li>
              You can also use{" "}
              <span className={styles.italic}>
                Pay over time with 6 months promotional financing on purchases
                of $299 or more
              </span>
              .
            </li>
          </ul>
        </div>
        <div className={styles.listContainer}>
          <h4 className={styles.subHeader}>{content.subHeaders[1]}:</h4>
          <ul className={styles.list}>
            <li>
              When referring to Synchrony HOME&rsquo;s everyday value proposition, the
              disclosure for 2% cash back should reference{" "}
              <span className={styles.italic}>1-2 billing cycles</span> (as
              shown in the val prop lockup) for issuing statement credits
              because this offer fulfillment is automated.
            </li>
            <li>
              When referring to offers where fulfillment is handled manually,
              the disclosure for 2% cash back should reference{" "}
              <span className={styles.italic}>90 days</span> for issuing
              statement credits.
            </li>
            <li>
              Never refer to the 2% cash back as &ldquo;rewards&rdquo; on any customer
              marketing or on the landing page.
            </li>
            <li>
            <span className={styles.italic}>Paid as a statement credit</span> must always accompany{" "}
              <span className={styles.italic}>2% cash back</span>. If space is an issue, use{" "}
              <span className={styles.italic}>
                2% back</span>. In that case, <span className={styles.italic}>paid as a statement credit</span>{" "}
              is not needed.
            </li>
            <li>
              It is legally approved to use either{" "}
              <span className={styles.italic}>
                under $299</span> OR <span className={styles.italic}>less than $299
              </span>{" "}
              when referring to 2% cash back.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ValPropTipsPage;
