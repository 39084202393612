import React from "react";
import styles from "../../SocialMediaSection.module.scss";
import TitleContentLockup from "components/global/TitleContentLockup";

const PaidPinterest = ({ content }) => {
  return (
    <div className={styles.containerDark}>
      <div className={styles.innerContainer}>
        <TitleContentLockup
          title={content.titleLockup.title}
          subTitle={content.titleLockup.subTitle}
          text={content.titleLockup.text}
        />
        <div className={styles.infoContainer}>
          <h3 className={styles.header}>
            <img src={content.pinterestIcon} alt="Pinterest" /> Pinterest
          </h3>
          <p className={styles.text}>
            This is best used as a platform to increase awareness of the brand.
            This is not the platform to test or advertise offers with a lot of
            text or requirements.
          </p>
          <ul className={styles.list}>
            <li>
              Imagery should be chosen to fit the aesthetic of Pinterest and
              focus on aspirational lifestyles.
            </li>
            <li>
              Though copy is included in these specs (same as organic), the user
              experience largely prevents the user from seeing it. For that
              reason, do not include material terms in the description.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default PaidPinterest;
