import React from "react";
import styles from "../../SocialMediaSection.module.scss";
import TitleContentLockup from "components/global/TitleContentLockup";

const PaidFacebook = ({ content }) => {
  return (
    <div className={styles.containerDark}>
      <div className={styles.innerContainer}>
        <TitleContentLockup
          title={content.titleLockup.title}
          subTitle={content.titleLockup.subTitle}
          text={content.titleLockup.text}
        />
        <div className={styles.infoContainer}>
          <h3 className={styles.header}>
            <img src={content.facebookIcon} alt="Facebook" /> Facebook
          </h3>
          <p className={styles.text}>
            For promoting offers, increasing awareness of the overall brand, or
            retargeting prospecting consumers. The tone and voice are the same,
            but keeping copy brief and engaging is especially key for grabbing
            attention.
          </p>
          <ul className={styles.list}>
            <li>
              Ad formats most often used include carousel, single video, and
              single image.
            </li>
            <li>
              Post must include primary text, image, website URL, and headline.
              Display link, button and description are optional.
            </li>
            <li>
            <a href="https://www.facebook.com/ads/tools/text_overlay" target="_blank" rel="noopener noreferrer" className={styles.link}>
                No more than 20% of the image should contain text.
            </a>
            </li>
            <li>
              Including a fake button in the image of the post is allowed, but
              not recommended.
            </li>
            <li>
              If including a description, do not put material terms there, as
              the full description is not visible at all sizes on all devices.
            </li>
            <li>
              Image:{" "}
              <span className={styles.thin}>
                Size:1080 x 1080 pixels or 1200 x 628 pixels; Ratio: 1:1
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default PaidFacebook;
