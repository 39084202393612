import React, { useState } from "react";
import styles from "./App.module.scss";
import { content } from "data/content";

import Header from "components/Header";
import TitlePage from "components/TitlePage";
import GuideIntro from "components/GuideIntro";
import TheBrandSection from "components/TheBrandSection";
import BrandElementsSection from "components/BrandElementsSection";
import DigitalAssetsPage from "components/DigitalAssetsSection";
import ContactPage from "components/ContactPage";
import CopyGuidanceTemp from "components/CopyGuidanceTemp";

export default function App() {
  //Toggle state for the quicktips section, anytime you click outside of the container it will close the sidebar.
  const [toggle, setToggle] = useState(false);
  const [mobileToggle, setMobileToggle] = useState(false);
  const openTooltip = () => {
    setToggle(!toggle);
    setMobileToggle(false);
  };
  const closeTooltip = () => {
    setToggle(false);
    setMobileToggle(false);
  };
  const openMobileMenu = () => {
    setMobileToggle(!mobileToggle);
    setToggle(false);
  };

  const closeMobileMenu = () => {
    setMobileToggle(false);
  };

  return (
    <div className={styles.container}>
      <Header
        toggle={toggle}
        setToggle={setToggle}
        openTooltip={openTooltip}
        closeTooltip={closeTooltip}
        openMobileMenu={openMobileMenu}
        closeMobileMenu={closeMobileMenu}
        mobileToggle={mobileToggle}
        setMobileToggle={setMobileToggle}
        content={content.quickTips}
      />
      <div onClick={closeTooltip}>
        <TitlePage />
        <GuideIntro content={content.guideIntroPage} />
        <CopyGuidanceTemp content={content.copyGuidanceTemp} />
        <TheBrandSection content={content} />
        <BrandElementsSection content={content} />
        <DigitalAssetsPage content={content} />
        <ContactPage />
      </div>
    </div>
  );
}
