import React from "react";
import styles from "../BannerAdsSection.module.scss";
import TitleContentLockup from "components/global/TitleContentLockup";

const BannerAdsMySyfPage = ({ content }) => {
  return (
    <div className={styles.containerGrey}>
      <div className={styles.innerContainer}>
        <TitleContentLockup title={content.titleLockup.title} />
        <div className={styles.columnContainer}>
          {content.mySyfBanners.map((type, idx) => {
            let key = "item-key-" + idx++;
            return (
              <div key={key}>
                <h3 className={styles.header}>{type.header}</h3>
                <span className={styles.subHeader}>{type.subHeaderA}:</span>
                <div className={styles.listWrapper}>
                  <ul className={styles.plainList}>
                    {type.sizes1.map((size, secondIdx) => {
                      let secondKey = "second-item-key-" + secondIdx++;
                      return <li key={secondKey}>{size}</li>;
                    })}
                  </ul>
                  <ul className={styles.plainList}>
                    {type.sizes2.map((size, thirdIdx) => {
                      let thirdKey = "third-item-key-" + thirdIdx++;
                      return <li key={thirdKey}>{size}</li>;
                    })}
                  </ul>
                </div>
                <img
                  src="/images/home_banner-ad-image-a.jpg"
                  alt="MySYF Banner Ad Example"
                  className={styles.image}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default BannerAdsMySyfPage;
