import React from "react";
import styles from "./BrandColorVariationsPage.module.scss";
import PageNumber from "components/global/PageNumber";
import SubSectionHeader from "components/global/SubSectionHeader";
import DownloadImage from "../../../global/DownloadImage/DownloadImage";

const BrandColorVariationsPage = ({ content, sectionTitle }) => {
  return (
    <div className={styles.container}>
      <SubSectionHeader />
      <div className={styles.innerContainer}>
        <div className={styles.infoContainer}>
          <h3 className={styles.header}>{content.header}</h3>
          <p>
            These are the only approved color variations of the brand mark and
            should be used whenever possible.
          </p>
          <ul className={styles.list}>
            <li>
              <span className={styles.bold}>Full Color:</span> Use this brand
              mark variation when the contrast value or background image is
              noticeably lighter than the Full Color brand mark carrier.
            </li>
            <li>
              <span className={styles.bold}>Full Color with Border:</span> Use
              this brand mark variation when the contrast value or background
              image is noticeably darker than the Full Color. A reversed color
              brand mark is NOT approved for use.
            </li>
            <li>
              <span className={styles.bold}>One Color (Cool Gray 11):</span> Use
              this brand mark variation when Full Color is not an option.
            </li>
            <li>
              <span className={styles.bold}>White or Black:</span> These brand
              mark variations should only be used when color is not an option
              (eg. black-and-white printing).
              <ul className={styles.subList}>
                <li>
                  <span className={styles.bold}>White (Reversed):</span> Use
                  this brand mark variation only on dark backgrounds when Full
                  Color is not an option.
                </li>
                <li>
                  <span className={styles.bold}>Solid Black:</span> Use this
                  brand mark variation only when black is the only option.
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div className={styles.iconLockup}>
          <div className={styles.imageContainer}>
            <DownloadImage
              downloadLink="/images/download/SYF_HOME_Brand_Mark.zip"
              fileName="SYF_HOME_Brand_Mark.zip"
              imageLink="/images/home_full-color-card-logo.png"
              class="grid-image"
            />
            <DownloadImage
              downloadLink="/images/download/SYF_HOME_Brand_Mark_Full-Color-Border.zip"
              fileName="SYF_HOME_Brand_Mark_Full-Color-Border.zip"
              imageLink="/images/home_full-color-border-logo.png"
              class="grid-image"
            />
            <DownloadImage
              downloadLink="/images/download/SYF_HOME_Brand_Mark_White.zip"
              fileName="SYF_HOME_Brand_Mark_White.zip"
              imageLink="/images/home_white-card-logo.png"
              class="grid-image"
            />
            <DownloadImage
              downloadLink="/images/download/SYF_HOME_Brand_Mark_One-Color_Cool-Gray11.zip"
              fileName="SYF_HOME_Brand_Mark_One-Color_Cool-Gray11.zip"
              imageLink="/images/home_one-color-card-logo.png"
              class="grid-image"
            />
          </div>
        </div>
      </div>
      <PageNumber
          sectionTitle={sectionTitle}
          pageNumber={content.pageNumber}
        />
    </div>
  );
};

export default BrandColorVariationsPage;
