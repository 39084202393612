import React from "react";
import styles from "../BannerAdsSection.module.scss";
import TitleContentLockup from "components/global/TitleContentLockup";

const BannerAdsMobilePage = ({ content }) => {
  return (
    <div className={styles.containerGrey}>
      <div className={styles.innerContainer}>
        <TitleContentLockup title={content.titleLockup.title} />
        {content.mobileAppBanners.map((type, idx) => {
          let key = "item-key-" + idx++;
          return (
            <div className={styles.columnContainer} key={key}>
              <div className={styles.wideColumn}>
                <h3 className={styles.header}>{type.header}</h3>
                <span className={styles.subHeaderThin}>{type.subHeader}:</span>
                <span className={styles.subHeader}>Sizes:</span>
                <ul className={styles.list}>
                  {type.sizes.map((size, secondIdx) => {
                    let secondKey = "second-item-key-" + secondIdx++;
                    return <li key={secondKey}>{size}</li>;
                  })}
                </ul>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default BannerAdsMobilePage;
