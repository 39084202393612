import React from "react";
import {useSpring, animated} from 'react-spring'
import { useInView } from "react-intersection-observer";
import styles from "./GoldCorePage.module.scss";
import TitleContentLockup from "components/global/TitleContentLockup";
import DisclaimerFooter from "components/global/DisclaimerFooter";
import PageNumber from "components/global/PageNumber";
 
const GoldCorePage = ({ content, sectionTitle }) => {
  const [ref, inView] = useInView({
    threshold: .1
  });

  const [animate, set] = useSpring(() => ({opacity: 1, 'left': 0, position: 'relative' }))
  set({'left': inView ? 0 : 3000})

  return (
    <div className={styles.container} ref={ref}>
      <div className={styles.innerContainer}>
        <TitleContentLockup
          title={content.titleLockup.title}
          path={content.titleLockup.path}
          text1={content.titleLockup.text1}
          text2={content.titleLockup.text2}
          text3={content.titleLockup.text3}
        />
        <div className={styles.infoWrapper}>
          <h3 className={styles.header}>{content.header}:</h3>
          <div className={styles.infoContainer} id={inView ? styles.dashEffect : ''}>
            <div className={styles.cardWhite}>
              <p className={styles.plus}>Plus</p>
              <h3 className={styles.cardNumber}>12&ndash;60</h3>
              <p className={styles.sublineBlue}>months</p>
              <p className={styles.subline}>Promotional Financing</p>
              <p className={styles.ctaDisclosure}>
                on qualifying purchases at thousands of participating Synchrony
                HOME locations.<sup>2</sup>
              </p>
            </div>
            <div className={styles.stroke} />
            <animated.div style={animate}>
            <div className={styles.cardLockup}>
            <img
              className={styles.image}
              src={content.cardArt}
              alt="HOME Credit Card"
            />
            </div>
            </animated.div>
          </div>
        </div>
      </div>
      <PageNumber
          sectionTitle={sectionTitle}
          pageNumber={content.pageNumber}
        />
      <DisclaimerFooter footer={content.disclaimer} />
    </div>
  );
};

export default GoldCorePage;
