import React from "react";
import styles from "./PathLockup.module.scss";

const PathLockup = props => {
  function renderPathLockup() {
    if (props.path === undefined) {
      return "";
    } else {
      return (
        <span className={styles.pathLockup}>
          Brand Vault: Downloads >> {props.path}
        </span>
      );
    }
  }
  return <>{renderPathLockup()}</>;
};

export default PathLockup;
