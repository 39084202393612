import React from "react";
import styles from "./ValuePropositionPage.module.scss";
import TitleContentLockup from "components/global/TitleContentLockup";
import { useWindowSize } from "utils/helpers";
import "./valprop.scss";

const ValuePropositionPage = ({ content }) => {
  const size = useWindowSize();

  return (
    <div className={styles.container}>
      <div className={styles.innerContainer}>
        <TitleContentLockup title1={content.header} path={content.path} />
        {/* <h3 className={styles.header}>{content.header}:</h3>
        <PathLockup path={content.path} /> */}
        <div className={styles.infoContainer}>
          {/* <img
            src="images/home_new-val-prop.png"
            alt=""
          /> */}
          <a
            href="/images/download/SYF_HOME_Value_Prop_new.zip"
            download="SYF_HOME_Value_Prop.zip"
            target="_blank"
            rel="noopener noreferrer"
            id={styles.imgDownload}
          >
            <img
              className={styles.gridImage}
              src="images/svg/download-icon-white.svg"
              alt=""
            />
            {size.width > 900 ? (
              <div className={styles.ctaWrapper}>
                <p className={styles.ctaHeaderLeft}>
                  At Select<br/> <span className={styles.bold}>Synchrony Home<br/></span>{" "}
                  locations, enjoy
                </p>
                <p className={styles.ctaHeader}>
                  Plus, enjoy{" "}
                  <span className={styles.bold}>everyday benefits</span> at
                  synchrony home &amp; other accepting locations
                </p>
              </div>
            ) : (
              ""
            )}
            <div className={styles.cardsContainer}>
              {size.width < 900 ? (
                <div className={styles.mobileBorder}>
                  <p className={styles.ctaHeaderM}>
                    Plus, enjoy{" "}
                    <span className={styles.bold}>everyday benefits</span> at
                    synchrony home &amp; other accepting locations
                  </p>
                  <div className={styles.cardBlue}>
                    <h3 className={styles.cardNumber}>6</h3>
                    <p className={styles.sublineBlue}>months</p>
                    <p className={styles.subline}>Promotional Financing</p>
                    <p className={styles.ctaDisclosure}>
                      on purchases of
                      <br />
                      $299 or more.<sup>1</sup>
                    </p>
                  </div>
                  <div className={styles.cardBlue}>
                    <h3 className={styles.cardNumber}>2%</h3>
                    <p className={styles.sublineBlue}>cash back</p>
                    <p className={styles.subline}>on purchases under $299</p>
                    <p className={styles.ctaDisclosure}>
                      paid as a statement credit.<sup>2</sup>
                    </p>
                  </div>
                </div>
              ) : (
                <>
                  <div className={styles.cardBlue}>
                    <h3 className={styles.cardNumber}>2%</h3>
                    <p className={styles.sublineWhite}>cash back</p>
                    <p className={styles.subline}>on purchases under $299</p>
                    <p className={styles.ctaDisclosure}>
                      paid as a statement credit.<sup>2</sup>
                    </p>
                  </div>
                  <div className={styles.cardBlue}>
                    <h3 className={styles.cardNumber}>6</h3>
                    <p className={styles.sublineWhite}>months</p>
                    <p className={styles.subline}>Promotional Financing</p>
                    <p className={styles.ctaDisclosure}>
                      on purchases of $299 or more.<sup>1</sup>
                    </p>
                  </div>
                </>
              )}
              <div className={styles.cardWhite}>
                {/* <div className={styles.cardInner}> */}
                <h3 className={styles.cardNumber}>12&ndash;60</h3>
                <p className={styles.sublineBlue}>months</p>
                <p className={styles.subline}>Promotional Financing</p>
                <p className={styles.ctaDisclosure}>
                  on qualifying purchases.<sup>1</sup>
                </p>
                {/* </div> */}
              </div>
              <p className={styles.ctaHeaderM}>
                at select<br/> <span className={styles.bold}>synchrony home<br/></span>{" "}
                locations, enjoy
              </p>
            </div>
            <section className="locations">
              <div className="locations__c1">
                <div className="locations__2up">
                  <div className="locations__item">
                    <h2 className="locations__heading">
                      Synchrony HOME Locations
                    </h2>
                    <p className="locations__subheading">
                      Featured Retailers and local businesses
                    </p>
                    <ul className="locations__list">
                      <li className="locations__list-item">
                        <span className="locations__list-icon">
                          <img src="/images/icon_chair.png" alt="" />
                        </span>
                        Furniture
                      </li>
                      <li className="locations__list-item">
                        <span className="locations__list-icon">
                          <img src="/images/icon_hammer.png" alt="" />
                        </span>
                        Home Improvement
                      </li>
                      <li className="locations__list-item">
                        <span className="locations__list-icon">
                          <img src="/images/icon_floor.png" alt="" />
                        </span>
                        Flooring
                      </li>
                      <li className="locations__list-item">
                        <span className="locations__list-icon">
                          <img src="/images/icon_lamp.png" alt="" />
                        </span>
                        Home D&eacute;cor
                      </li>
                      <li className="locations__list-item">
                        <span className="locations__list-icon">
                          <img src="/images/icon_washer.png" alt="" />
                        </span>
                        Electronics &amp; Appliances
                      </li>
                      <li className="locations__list-item">
                        <span className="locations__list-icon">
                          <img src="/images/icon_bed.png" alt="" />
                        </span>
                        Mattresses &amp; Bedding
                      </li>
                      <li className="locations__list-item">
                        <span className="locations__list-icon">
                          <img src="/images/icon_wrench.png" alt="" />
                        </span>
                        Service Providers
                      </li>
                      <li className="locations__list-item">
                        <span className="locations__list-icon">
                          <img src="/images/icon_flower-pot.png" alt="" />
                        </span>
                        Outdoor Patio &amp; Garden
                      </li>
                    </ul>
                  </div>
                  <div className="locations__item">
                    <h2 className="locations__heading">
                      Other Accepting Locations
                    </h2>
                    <p className="locations__subheading">
                      Thousands of additional locations nationwide
                    </p>
                    <ul className="locations__list">
                      <li className="locations__list-item">
                        <span className="locations__list-icon">
                          <img src="/images/icon_chair.png" alt="" />
                        </span>
                        Furniture
                      </li>
                      <li className="locations__list-item">
                        <span className="locations__list-icon">
                          <img src="/images/icon_floor.png" alt="" />
                        </span>
                        Flooring
                      </li>
                      <li className="locations__list-item">
                        <span className="locations__list-icon">
                          <img src="/images/icon_washer.png" alt="" />
                        </span>
                        Electronics &amp; Appliances
                      </li>
                      <li className="locations__list-item">
                        <span className="locations__list-icon">
                          <img src="/images/icon_bed.png" alt="" />
                        </span>
                        Mattresses &amp; Bedding
                      </li>
                      <li className="locations__list-item">
                        <span className="locations__list-icon">
                          <img src="/images/icon_wrench.png" alt="" />
                        </span>
                        Service Providers
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </section>
            {/* <div className={styles.disclosure}>
            <p>
              <sup>1</sup>Net card purchases (purchases minus returns and
              adjustments) less than $299 made with the Synchrony HOME Credit
              Card will earn 2% cash back rewards paid as a statement credit.
              Statement credits will be issued within 1-2 billing cycles after
              qualifying purchase is made. Fees and interest charges do not
              qualify for rewards. Account must remain open, in good standing,
              and not become delinquent. Subject to credit approval. We reserve
              the right to discontinue or alter the terms of this offer at any
              time.
            </p>
            <p>
              <sup>2</sup>Subject to credit approval. Minimum monthly payments
              required. Promotional financing offers available at the time of
              purchase may vary by location. See store for details.
            </p>
          </div> */}
          </a>
        </div>
      </div>
    </div>
  );
};

export default ValuePropositionPage;
