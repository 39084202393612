import React from "react";
import { useSpring, animated } from "react-spring";
import { getQuickTips } from "../../../data/tooltip";
import styles from "./Tooltip.module.scss";

const Tooltip = ({ toggle }) => {
  const [animateMain, set] = useSpring(() => ({ height: "0vh" }));
  set({ height: toggle ? "100vh" : "0vh" });

  const [animateInner] = useSpring(() => ({
    to: { right: 0 },
    delay: 500,
    from: { right: -1000 }
  }));

  return (
    <animated.div className={styles.container} id="navbar" style={animateMain}>
      <div className={styles.content}>
        {getQuickTips(styles.innerList, styles.italic).map((item, idx) => {
          let key = "item-key-" + idx++;
          return (
            <animated.div
              className={styles.innerContent}
              style={animateInner}
              key={key}
            >
              <h4 className={styles.header}>{item.title}</h4>
              <ul className={styles.list}>
                {item.items.map((innerItem, idx) => {
                  let innerKey = "inner-item-key-" + idx++;
                  return <li key={innerKey}>{innerItem}</li>;
                })}
              </ul>
            </animated.div>
          );
        })}
      </div>
    </animated.div>
  );
};

export default Tooltip;
