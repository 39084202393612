import React from "react";
import BrandMarkIntroPage from "./BrandMarkIntroPage";
import BrandSpacingPage from "./BrandSpacingPage";
import BrandColorVariationsPage from "./BrandColorVariationsPage";
import BrandDontsPage from "./BrandDontsPage";

const BrandMarkSection = ({ content, sectionTitle }) => {
  return (
    <div>
      <BrandMarkIntroPage
        content={content.brandElements.brandMarkSection.brandMarkIntroPage}
        sectionTitle={sectionTitle}
      />
      <BrandSpacingPage
        content={content.brandElements.brandMarkSection.brandSpacingPage}
        sectionTitle={sectionTitle}
      />
      <BrandColorVariationsPage
        content={
          content.brandElements.brandMarkSection.brandColorVariationsPage
        }
        sectionTitle={sectionTitle}
      />
      <BrandDontsPage
        content={content.brandElements.brandMarkSection.brandDontsPage}
        sectionTitle={sectionTitle}
      />
    </div>
  );
};

export default BrandMarkSection;
