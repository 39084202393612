import React from "react";

import styles from "./AdditionalBenefitsPage.module.scss";

const AdditionalBenefitsPage = ({ content }) => {
  return (
    <div className={styles.container}>
      <div className={styles.innerContainer}>
        <h3 className={styles.header}>{content.header}:</h3>
        <ul className={styles.list}>
          {content.listItems.map((item, idx) => {
            return <li key={idx}>{item}</li>;
          })}
        </ul>
        <div className={styles.disclosure}>
          {content.disclosures.map((item, idx) => {
            return <p key={idx}>{item}</p>; 
          })}
        </div>
      </div>
    </div>
  );
};

export default AdditionalBenefitsPage;
