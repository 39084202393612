import React from "react";
import styles from "./SocialMediaIntro.module.scss";
import TitleContentLockup from "components/global/TitleContentLockup";
import PageNumber from "components/global/PageNumber";

const SocialMediaIntro = ({ content, sectionTitle }) => {
  return (
    <div className={styles.container}>
      <div className={styles.innerContainer}>
        <TitleContentLockup
          title={content.titleLockup.title}
          text={content.titleLockup.text}
        />
        <div className={styles.infoContainer}>
          <p className={styles.text}>
            The Synchrony HOME program is all about encouraging its audience &mdash;
            cardholders and prospects alike &mdash; to create their dream homes, and
            there&rsquo;s no better venue for that kind of messaging than social
            media.
          </p>
          <p className={styles.text}>
            When promoting the Synchrony HOME brand on social, focus on using
            engaging messaging and imagery to show the brand&rsquo;s followers how the
            credit card can turn their dream into a reality.
          </p>
          <p className={styles.text}>
            For specs on post types not listed here, please visit each
            respective site&rsquo;s&nbsp;(
            <a
              href="https://business.pinterest.com/en/Pinterest-product-specs"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.link}
            >
              Pinterest
            </a>{" "}
            ,{" "}
            <a
              href="https://www.facebook.com/business/"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.link}
            >
              Facebook
            </a>{" "}
            /{" "}
            <a
              href="https://www.facebook.com/business/"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.link}
            >
              Instagram
            </a>
            ) ad center.{" "}
          </p>
        </div>
      </div>
      <PageNumber
          sectionTitle={sectionTitle}
          pageNumber={content.pageNumber}
        />
    </div>
  );
};

export default SocialMediaIntro;
