import React from "react";
import styles from "./CopyGuidanceTemp.module.scss";
import PageNumber from "components/global/PageNumber";
import TitleContentLockup from "components/global/TitleContentLockup";

const CopyGuidanceTemp = ({ content }) => {
  return (
    <div className={styles.container}>
      <div className={styles.innerContainer}>
        <TitleContentLockup
          title1={content.titleLockup.title1}
          title={content.titleLockup.title}
          text={content.titleLockup.text}
        />
        <div className={styles.table}>
          <div className={styles.infoContainer}>
            <h3>Previous Copy</h3>
            <div className={styles.row}>
              <p>Over a million locations nationwide</p>
            </div>
            <div className={styles.row}>
              <p>
                Everything for your home
                <br />
                One card for everything in the home
              </p>
            </div>
            <div className={styles.row}>
              <p>For home-related purchases, big and small</p>
            </div>
            <div className={styles.row}>
              <p>Benefits for all their home purchases</p>
            </div>
            <div className={styles.row}>
              <p>
                Accepted at thousands of retailers, from furniture and d&eacute;cor to
                appliances and more
              </p>
            </div>
            <div className={styles.row}>
              <p>
                Access to 5 million cardholders with $22 billion in available
                credit*
              </p>
            </div>
          </div>
          <div className={styles.infoContainer}>
            <h3>New Copy</h3>
            <div className={styles.row}>
              <p>Thousands of locations nationwide</p>
            </div>
            <div className={styles.row}>
              <p>The card for your home</p>
            </div>
            <div className={styles.row}>
              <p>Remove &ldquo;big and small&rdquo;</p>
            </div>
            <div className={styles.row}>
              <p>Remove &ldquo;all&rdquo;</p>
            </div>
            <div className={styles.row}>
              <p>No change</p>
            </div>
            <div className={styles.row}>
              <p>No change</p>
            </div>
            <p className={styles.disclosureText}>*as of 6/30/2019</p>
          </div>
        </div>
      </div>
      <PageNumber pageNumber={content.pageNumber} />
    </div>
  );
};

export default CopyGuidanceTemp;
