import React from "react";
import styles from "./NameUsagePage.module.scss";
import TitleContentLockup from "components/global/TitleContentLockup";
import PageNumber from "components/global/PageNumber";

const NameUsagePage = ({ content, sectionTitle }) => {
  return (
    <div>
      <div className={styles.container}>
        <TitleContentLockup title={content.titleLockup.title} />
        <div className={styles.infoContainer}>
          <p>
            Synchrony HOME&trade; is a trademark. The first occurrence of the name
            should include the superscript &trade; following the brand name.
          </p>
          <h3>{content.header}:</h3>
          <ul className={styles.list}>
              <li>
              <span className={styles.italic}>Synchrony HOME Credit Card</span> is a proper noun and should always be capitalized as one.
              </li>
              <li>
              <span className={styles.italic}>Synchrony HOME</span> is the appropriate way to refer to the brand and the network overall in B2C communications.
              <ul className={styles.innerList}>
                <li>For B2B, the brand can be referred to as the <span className={styles.italic}>Synchrony HOME Credit Card</span> or <span className={styles.italic}>Synchrony HOME program</span>.</li>
              </ul>
              </li>
              <li>
              <span className={styles.italic}>Your credit card</span> is acceptable for use within small word counts 1) if the card art is present, and 2) the audience is current cardholders.
              </li>
              <li>
              <span className={styles.italic}>HOME</span> may never be used on its own, or used in all caps to refer to the general concept of home.
              </li>
              <li>
              <span className={styles.italic}>Cardholders</span> (one word) should be used when paired with the name of the credit card: Synchrony HOME Credit Cardholders. In all other applications, <span className={styles.italic}>cardholders</span> is lowercase.
              </li>
            </ul>
        </div>
      </div>
      <PageNumber sectionTitle={sectionTitle} pageNumber={content.pageNumber} />
    </div>
  );
};

export default NameUsagePage;
