import React from 'react';
import './DownloadImage.scss';

const DownloadImage = (props) => (
  <a
    href={props.downloadLink}
    target="_blank"
    rel="noopener noreferrer"
    download={props.fileName}
    className={props.class}>
    <img src={props.imageLink} alt="" id={props.id}/>
    <img
      className='grid-image-download'
      src="images/svg/download-icon-white.svg"
      alt=""
    />
  </a>
);
 
export default DownloadImage;
