import React from "react";
import styles from "./CardArtPage.module.scss";
import PageNumber from "components/global/PageNumber";
import DisclaimerFooter from "components/global/DisclaimerFooter";
import TitleContentLockup from "components/global/TitleContentLockup";
import DownloadImage from "../../global/DownloadImage/DownloadImage";

const CardArtPage = ({ content, sectionTitle }) => {
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <TitleContentLockup
          title={content.titleLockup.title}
          text={content.titleLockup.text}
          path={content.titleLockup.path}
          listItem1={content.titleLockup.listItem1}
          listItem2={content.titleLockup.listItem2}
        />
        <div className={styles.innerContainer}>
          <h3 className={styles.header}>{content.header}</h3>
          <div className={styles.imageContainer}>
            <div className={styles.imageInner}>
              <span className={styles.subText}>Single Card (Primary)</span>
              <DownloadImage
                downloadLink="/images/download/SYF-HOME-Card-Art-Single-Card-(Primary).zip"
                fileName="SYF-HOME-Card-Art-Single-Card-(Primary).zip"
                imageLink="/images/home_blue-card-art-shadowboxed-large.png"
                class="grid-image"
                id="image-enlarge"
              />
            </div>
            <div className={styles.imageInner}>
              <span className={styles.subText}>Single Card (Secondary)</span>
              <DownloadImage
                downloadLink="/images/download/SYF-HOME-Card-Art-Single-Card-(Secondary).zip"
                fileName="SYF-HOME-Card-Art-Single-Card-(Secondary).zip"
                imageLink="/images/home_blue-card-art-flat.png"
                class="grid-image"
              />
            </div>
            <div className={styles.imageInner}>
              <span className={styles.subText}>
                Two-Card Neutral Spray<sup>1</sup>
              </span>
              <DownloadImage
                downloadLink="/images/download/SYF-HOME-Card-Art-Two-Card-Neutral-Spray.zip"
                fileName="SYF-HOME-Card-Art-Two-Card-Neutral-Spray.zip"
                imageLink="/images/home_spray-card-art.png"
                class="grid-image"
              />
            </div>
            <div className={styles.imageInner}>
              <span className={styles.subText}>Single Card (Business)</span>
              <DownloadImage
                downloadLink="/images/download/SYF-HOME-Card-Art-Single-Card-(Business).zip"
                fileName="SYF-HOME-Card-Art-Single-Card-(Business).zip"
                imageLink="/images/home_blue-card-art-flat-bus.png"
                class="grid-image"
              />
            </div>
            <div className={styles.imageInner}>
              <span className={styles.subText}>
                Teal Card Spray<sup>2</sup>
              </span>
              <DownloadImage
                downloadLink="/images/download/SYF-HOME-Card-Art-Teal-Card-Spray.zip"
                fileName="SYF-HOME-Card-Art-Teal-Card-Spray.zip"
                imageLink="/images/home_spray-card-art-all.png"
                class="grid-image"
              />
            </div>
            <div className={styles.imageInner}>
              <span className={styles.subText}>Brand Mark</span>
              <DownloadImage
                downloadLink="images/download/SYF_HOME_Brand_Mark.zip"
                fileName="SYF_HOME_Brand_Mark.zip"
                imageLink="/images/home_blue-dark-card-art-small.png"
                class="grid-image"
              />
            </div>
          </div>
        </div>
      </div>
      <PageNumber sectionTitle={sectionTitle} pageNumber={content.pageNumber} />
      <div className={styles.disclaimer}>
        <sup>1</sup> Two-Card Neutral Spray: for instances when you know the
        audience has a custom card of some sort.
        <br />
        <sup>2</sup> Teal Card Spray: for instances when we know the audience
        includes cardholders who may have a custom or a generic card.
      </div>
      <DisclaimerFooter footer={content.disclaimer} />
    </div>
  );
};

export default CardArtPage;
