import React, { Component } from "react";
import styles from "./ColorPalettePage.module.scss";

class ColorControls extends Component {
  constructor(props) {
    super(props);

    this.state = {
      colorIndex: 0,
      colorList: [
        this.props.colors.hex,
        this.props.colors.pms,
        this.props.colors.cmyk,
        this.props.colors.rgb
      ],
      btn: []
    };
    this.colorMap = ["hex", "pms", "cmyk", "rgb"];

    this.copy = this.copy.bind(this);
    this.switch = this.switch.bind(this);
    this.initialState = { ...this.state };
  }

  reset() {
    this.setState(this.initialState);
  }

  copy() {
    this.props.copy(this.props.colorRef).then(success => {
      if (success) {
        this.refs.successMessage.innerText = "Copied ✓";
        this.refs.successMessage.classList.add("visible");
        setTimeout(() => {
          this.refs.successMessage.classList.remove("visible");
          setTimeout(() => {
            this.refs.successMessage.innerText = "";
          }, 400);
        }, 400);
      }
    });
  }

  switch() {
    this.setState({ colorIndex: this.state.colorIndex + 1 }, () => {
      const nextIndex = this.state.colorIndex % this.colorMap.length;
      const nextColorIndex = this.colorMap[nextIndex];
      const nextColor = this.props.colors[nextColorIndex];
      this.props.switch(nextColor, this.props.colorRef);
    });
    if (this.state.colorIndex === 3) {
      this.reset();
    }
  }

  // create the underline effect and dynamically set the class.
  isColorActive(idx) {
    if (this.state.colorIndex === idx && !this.props.inverted) {
      return styles.underlined;
    } else if (this.state.colorIndex === idx && this.props.inverted) {
      return styles.underlinedDark;
    }
  }

  render() {
    const invertedSwitch = this.props.inverted
      ? "/images/svg/double-chevron-down-icon-dark.svg"
      : "/images/svg/double-chevron-down-icon.svg";
    const invertedCopy = this.props.inverted
      ? "/images/svg/copy-icon-dark.svg"
      : "/images/svg/copy-icon-white.svg";

    return (
      <div>
        <ul
          className={styles.list}
          id={this.props.inverted ? styles.dkList : ""}
        >
          <li>
            <span>{this.props.header}</span>
          </li>
          {this.state.colorList.map((color, idx) => {
            return (
              <li key={idx} className={this.isColorActive(idx)}>
                {color}
              </li>
            );
          })}
        </ul>
        <div
          id={this.props.inverted ? styles.dkList : ""}
          ref="successMessage"
          className={styles.copyContainer}
        />
        <button onClick={this.copy} className={styles.copyButton}>
          <img src={invertedCopy} alt="Copy Icon" className={styles.copyIcon} />
        </button>
        <button className={styles.switchButton} onClick={this.switch}>
          <img
            src={invertedSwitch}
            alt="Switch Icon"
            className={styles.switchIcon}
          />
        </button>
      </div>
    );
  }
}

export default ColorControls;
