import React from "react";
import styles from "../BannerAdsSection.module.scss";
import TitleContentLockup from "components/global/TitleContentLockup";

const BannerAdsDisplayPage = ({ content }) => {
  return (
    <div className={styles.container}>
      <div className={styles.innerContainer}>
        <TitleContentLockup title={content.titleLockup.title} />
        <div className={styles.columnContainer}>
          {content.bannerAdsDisplay.map((type, idx) => {
            let key = "item-key-" + idx++;
            return (
              <div className={styles.columnContainer} key={key}>
                <div className={styles.wideColumn}>
                  <h3 className={styles.header}>{type.nativeSearch.header}</h3>
                  <span className={styles.subHeader}>Copy:</span>
                  <ul className={styles.list}>
                    <li>{type.nativeSearch.copy}</li>
                  </ul>
                  <span className={styles.subHeader}>Sizes:</span>
                  <ul className={styles.list}>
                    {type.nativeSearch.sizes.map((size, secondIdx) => {
                      let secondKey = "second-item-key-" + secondIdx++;
                      return <li key={secondKey}>{size}</li>;
                    })}
                  </ul>
                </div>
                <div className={styles.wideColumn}>
                  <h3 className={styles.header}>
                    {type.displayBanners.header}
                  </h3>
                  <span className={styles.subHeader}>Sizes:</span>
                  <ul className={styles.list}>
                    {type.displayBanners.sizes.map((size, thirdIdx) => {
                      let thirdKey = "third-item-key-" + thirdIdx++;
                      return <li key={thirdKey}>{size}</li>;
                    })}
                  </ul>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default BannerAdsDisplayPage;
