import React from "react";
import { Element } from "react-scroll";
import { useInView } from "react-intersection-observer";
import styles from "./ContactPage.module.scss";

const ContactPage = () => {
  const [ref, inView] = useInView({
    /* Optional options */
    threshold: .5
  });
  return (
    <Element id="contact-section" name="contact-section">
      <div className={styles.container} ref={ref}>
        <div className={styles.innerContainer}>
          <div className={styles.infoLockup}>
            <div className={styles.textContainer}>
              <span className={styles.header} id={inView ? styles.dashEffect : ''}>Contact Us</span>
            </div>
          </div>
          <div className={styles.infoContainer}>
            <p className={styles.text}>
              We&rsquo;re excited about the Synchrony&nbsp;HOME Credit Card program and all 
              the ways our customers can use it to create the homes of their
              dreams.
            </p>
            <p className={styles.text}>
              If you&rsquo;re interested in learning more about the Synchrony HOME
              program or have additional questions, please reach out to{" "}
              <span className={styles.bold}>Kirsty&nbsp;Wertz</span> at{" "}
              <a href="mailto:Kirsty.wertz@syf.com" className={styles.link}>
                kirsty.wertz@syf.com
              </a>
              .
            </p>
          </div>
        </div>
      </div>
    </Element>
  );
};

export default ContactPage;
